import React from "react";
import { createStore } from 'redux'
import { connect } from 'react-redux';
import '../styles/Home.css'
import Util from '../util.js'
import Nav_State from '../states/Nav_State.js'
import '../styles/Nav.css'
import cookies from "../Cookies";
import AccountCircle from '@material-ui/icons/AccountCircle'

var store_nav = createStore(Nav_State.reducer_nav)
 
class Main extends React.Component {
constructor(props) {
    super(props);  
    this.state = {
      key : Date.now()
    };
  }

async componentDidMount() {
  store_nav.subscribe(()=>{
    this.setState({key:Date.now()})
  })
  
  let current_url = window.location.hash.replace("#", "")
  store_nav.dispatch({type:1, current_url : current_url})
  window.addEventListener('hashchange', function(){
   store_nav.dispatch({type:1,current_url : window.location.hash.replace("#", "") })
  })
}

render() {
  
  let current_url = store_nav.getState().current_url;
  current_url =  window.location.hash.replace("#", "")
  const {queued_quotes} = this.props

  let role = ""

  if(cookies.role === Util.USER_ROLES.ADMIN) 
    role = "Administrator"
  else if(cookies.role === Util.USER_ROLES.STATION_ADMIN ) 
    role = "Station Admin"
  else if(cookies.role === Util.USER_ROLES.SALES_STD) 
    role = "Sales STD"
  else if(cookies.role === Util.USER_ROLES.SALES_LITE) 
    role = "Sales Lite"
  else if(cookies.role === Util.USER_ROLES.OPS_LITE) 
    role = "Ops Lite"
  else if(cookies.role === Util.USER_ROLES.OPS_STD) 
    role = "Ops STD"
  
  return (
    <div className="row col main-nav" key={this.state.key} >

      <ul className="nav nav-tabs ">
        <li className="nav-item">
          <a 
            className={"nav-link " + (current_url === "/" ? "current_button" : "")}
            onClick={(e)=> store_nav.dispatch({type:1, current_url : "/"})}
            href="#/"
          >Home
          </a>
        </li>
        
        {this.props.loggedIn ?
        [
          <li className="nav-item">
            <a 
              className={"nav-link " + (current_url === "/List" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/List"})}
              href="#/List" 
            >
              List{queued_quotes !== 0 ?  < span style = {{marginLeft : '1em'}} class="badge badge-purple"> {queued_quotes} </span> : null }
            </a>
          </li>
          ,
          cookies.rights && cookies.rights.quote_view ? 
          <li className="nav-item">
            <a 
              className={"nav-link " + (current_url.substring(0,7) === "/Editor" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Editor"})}
              href="#/Editor">
                Quote
            </a>
          </li>
          : 
          <li></li>
          ,
          cookies.rights && cookies.rights.analytics_view ?
          <li className="nav-item">
            <a 
              className={"nav-link " + (current_url === "/Analytics" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Analytics"})}
              href="#/Analytics"
            >
              Analytics
            </a>
          </li>
          :
          <li></li>
          ,
          cookies.rights && cookies.rights.rates_view ?
          
            <li className="nav-item">
            <a 
              className={"nav-link " + (current_url === "/Rates" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Rates"})}
              href="#/Rates"
            >
              Rates
            </a>
          </li> 
          
          : 

          <li></li>
 
          ,
 
         
          cookies.rights && cookies.rights.users_view ?
          <li className="nav-item">
            <a 
              className={"nav-link " + (current_url === "/Users" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Users"})}
              href="#/Users"
            >
                Users
            </a>
          </li>
          :
         <li></li>
         ,

         cookies.rights && cookies.rights.stations_view ?
         <li className="nav-item">
           <a 
             className={"nav-link " + (current_url === "/Stations" ? "current_button" : "")} 
             onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Stations"})}
             href="#/Stations"
           >
               Stations
           </a>
         </li>
         :
        <li></li>,

        cookies.rights && cookies.rights.settings_view ?
   
        <li className="nav-item">
          <a 
            className={"nav-link " + (current_url === "/Settings" ? "current_button" : "")} 
            onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Settings"})}
            href="#/Settings">
              Settings
          </a>
        </li>
        :
        <li></li>
        
      
        ]
        :
        null
        }

        <li className="nav-item">
          <a 
            className={"nav-link " + (current_url === "/Help" ? "current_button" : "")} 
            onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Help"})}
            href="#/Help"
          >
            Help 
          </a>
        </li>

      </ul >
        
      {this.props.loggedIn  &&
        <ul className="nav nav-tabs station-info" style={{background:"none"}}>
          
          <li className="nav-item">
            <p style={{margin : "1em"}}><strong>Default Station :</strong> {cookies.station} ({cookies.sales_region})  |  <strong>Role : </strong> {role} {cookies.region === "WLD" ? " - HQ" : ""} </p>
          </li>

            <li className="nav-item">
            <a 
              className={"nav-link " + (current_url === "/Profile" ? "current_button" : "")} 
              onClick={(e)=> store_nav.dispatch({type:1, current_url : "/Profile"})}
              href="#/Profile"
            >
              <AccountCircle/> 
            </a>
          </li>

        </ul>
      }

     
    
    </div> 
  )
}
}

export default connect(
  (state) => ({
    configuration: state.configuration, 
    authorized_stations : state.authorized_stations, 
    loggedIn : state.loggedIn, 
    queued_quotes : state.queued_quotes
  })
)(Main)