import React from "react";
import watch from "redux-watch";
import { connect } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import * as d3 from "d3";
import {
  Slider,
  Backdrop,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../styles/Analytics.css";
import Analytics_State from "../states/Analytics_State.js";
import Cookies from "universal-cookie";
import _ from "lodash";
import conf from "../config.js";
import Routes from "../Routes.js";
import Util from "../util.js";
import cookies from "../Cookies";
import Refresh from "@material-ui/icons/Refresh";
import GetAppIcon from "@material-ui/icons/GetApp";
import Interweave from "interweave";

var _cookies = new Cookies(cookies);
var config = conf.config;
var session_expiration_date = new Date();

session_expiration_date.setDate(
  session_expiration_date.getDate() + config.session_expiration_delay
);

const logger = createLogger({
  //predicate, // if specified this function will be called before each action is processed with this middleware.
  //collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  duration: false, // print the duration of each action?
  timestamp: true, // print the timestamp with each action?

  //level : 'log': 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
  //colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
  //titleFormatter, // Format the title used when logging actions.

  //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
  //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
  //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.

  logger: console, // implementation of the `console` API.
  logErrors: true, // should the logger catch, log, and re-throw errors?

  diff: false, // (alpha) show diff between states?
  //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`
});
var store_analytics = createStore(
  Analytics_State.reducer_analytics,
  applyMiddleware(logger)
);

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      export_csv: "1",
      key: Date.now(),
    };

    this.STATE_COLORS = {
      ACCEPTED: "#86c2ac",
      DRAFT: "#dadfe0",
      QUOTED: "#ffe600",
      CANCELLED: "red",
      REJECTED: "red",
    };

    this.months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    this.status_list = ["ACCEPTED", "QUOTED", "CANCELLED", "REJECTED", "DRAFT"];
  }

  async componentDidMount() {
    document.title = "Quantum - Analytics";

    store_analytics.subscribe((v) => {
      var expiration_date = new Date();
      expiration_date.setMinutes(2);
      _cookies.set("analytics_filters", store_analytics.getState().filters, {
        path: "/",
        expires: expiration_date,
      });
    });

    let w2 = watch(store_analytics.getState, "filters.delay");
    store_analytics.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    if (cookies.analytics_filters) {
      store_analytics.dispatch({
        type: 1,
        filters: true,
        agent_code: cookies.analytics_filters.agent_code,
        agent: cookies.analytics_filters.agent,
        only_user: cookies.analytics_filters.only_user,
        origin: cookies.analytics_filters.origin,
        dest: cookies.analytics_filters.dest,
        carrier: cookies.analytics_filters.carrier,
        delay: cookies.analytics_filters.delay,
      });
    }

    this.loadData();
  }

  async loadData() {
    let analytics_state = store_analytics.getState();
    store_analytics.dispatch({ type: 1, page_loading_spinner: true });

    let params = { ...analytics_state.filters };

    params.user = analytics_state.filters.only_user
      ? cookies.account
      : undefined;
    params.station = cookies.station;
    params.carrier = params.carrier.join(",");
    params.origin = params.origin.join(",");
    params.dest = params.dest.join(",");
    params.commodity = params.commodity.join(",");

    if (params.follow_up) params.user_follow_up = cookies.account;

    let res = await Routes.getKPI(params);

    store_analytics.dispatch({ type: 1, page_loading_spinner: false });

    if (!res.data) return store_analytics.dispatch({ type: 1, alert: res.msg });

    let data = res.data;
    let custom_list = this.props.custom_list;
    let exch_rate = this.props.exch_rate;
    let default_station = cookies.station;
    custom_list = custom_list ? custom_list : [];
    let station_config = custom_list.filter(
      (c) => c.station === default_station && c.carrier === "***"
    )[0];
    let default_currency =
      station_config && station_config.config
        ? station_config.config.default_currency
        : "";

    store_analytics.dispatch({
      type: 1,
      data: data,
      default_currency: default_currency,
      default_station: default_station,
      exch_rate: exch_rate,
    });

    this.setState({ key: Date.now() });
  }

  draw_hit_rate(data) {
    // PRE-VIZ CALCULATION
    let hit_rate = []; // [ {week : 28, value : 0.1, count : 100, sum}, {week : 29, value 0.2, count : 200} ..]

    data.forEach((q) => {
      let week = new Date(q.time_stamp).getWeek();
      let booked = q.status === "BOOKED" ? 1 : 0;
      let pending = q.status === "QUOTED" ? 1 : 0;

      //check if first element of week
      let json = hit_rate.filter((r) => r.week === week)[0];
      if (json === undefined) {
        hit_rate.push({
          week: week,
          booked_rate: booked,
          count: 1,
          booked: booked,
          pending: pending,
        });
      } else {
        let record = hit_rate.filter((r) => r.week === week)[0];
        hit_rate.filter((r) => r.week === week)[0].booked += booked;
        hit_rate.filter((r) => r.week === week)[0].count++;
        hit_rate.filter((r) => r.week === week)[0].booked_rate =
          Math.round((10 * 100 * record.booked) / record.count) / 10;
        hit_rate.filter((r) => r.week === week)[0].pending += pending;
      }
    });

    if (hit_rate)
      hit_rate.sort(function (a, b) {
        return a.week - b.week;
      });

    let maxCount = Math.max.apply(
      null,
      hit_rate.map((q) => q.count)
    );

    //let maxValue = Math.max.apply( null, hit_rate.map((q) => q.sum));
 
    let maxValue2 = Math.max.apply(
      null,
      hit_rate.map((q) => q.booked_rate)
    );

    //CANVAS
    const canvasHeight = 300;
    const canvasWidth =
      document.getElementById("canvas_chart_hit_rate").offsetWidth - 20;
    let canvasMarginY = 40;

    const scale = (canvasHeight - canvasMarginY - 20) / (maxCount * 1.001);
    const scale2 = (canvasHeight - canvasMarginY - 30) / (maxValue2 * 1.001);

    const svgCanvas = d3
      .select("#chart_hit_rate")
      .append("svg")
      .attr("width", canvasWidth)
      .attr("height", canvasHeight)
      .attr("id", "canvas");

    /////////////: AXIS

    let tick = maxCount / 5;

    for (
      let i = 0;
      i < maxCount;
      i += Math.max(Math.round(tick / 50) * 50, 10)
    ) {
      svgCanvas
        .append("line")
        .style("stroke", "lightgrey")
        .style("stroke-width", 1)
        .attr("x1", 0)
        .attr("y1", canvasHeight - canvasMarginY - i * scale)
        .attr("x2", canvasWidth)
        .attr("y2", canvasHeight - canvasMarginY - i * scale);

      svgCanvas
        .append("text")
        .attr("x", 20)
        .attr("y", canvasHeight - canvasMarginY - i * scale + 10)
        .text(i)
        .attr("fill", "#949494");
    }

    svgCanvas
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", -(canvasHeight - canvasMarginY) / 2)
      .attr("y", 10)
      .attr("transform", "rotate(-90)")
      .text("Total Number of Quotes")
      .attr("fill", "#949494")
      .attr("class", "barchart-label");

    let bar_width = 25;
    hit_rate.forEach((d, i) => {
      //VALUE
      svgCanvas
        .append("text")
        .attr("x", 2 + 50 + (i * canvasWidth) / hit_rate.length)
        .attr("y", canvasHeight - canvasMarginY - 10 - d.count * scale)
        .text(d.count)
        .attr("fill", Util.COLOR_PALETTE.ECS_MAIN)
        .attr("class", "barchart-label");

      //WEEK TICK
      svgCanvas
        .append("text")
        .attr("x", 50 + (i * canvasWidth) / hit_rate.length)
        .attr("y", 15 + canvasHeight - canvasMarginY)
        .text("W" + d.week)
        .attr("fill", "#949494")
        .attr("class", "barchart-label");

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.count * scale)
        .attr("fill", Util.COLOR_PALETTE.ECS_MAIN)
        .attr("x", 50 + (i * canvasWidth) / hit_rate.length)
        .attr("y", canvasHeight - canvasMarginY - d.count * scale);

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.pending * scale)
        .attr("fill", "#ffd863")
        .attr("x", 50 + (i * canvasWidth) / hit_rate.length)
        .attr("y", canvasHeight - canvasMarginY - d.pending * scale);
    });

    ///// CURVE HIT RATE
    hit_rate.forEach((d, i) => {
      let x1 = bar_width / 2 + 50 + (i * canvasWidth) / hit_rate.length;
      let y1 = canvasHeight - canvasMarginY - d.booked_rate * scale2;

      if (i < hit_rate.length - 1) {
        let x2 = bar_width / 2 + 50 + ((i + 1) * canvasWidth) / hit_rate.length;
        let y2 =
          canvasHeight - canvasMarginY - hit_rate[i + 1].booked_rate * scale2;

        svgCanvas
          .append("line")
          .style("stroke", "#d9646f")
          .style("stroke-width", 2)
          .attr("x1", x1)
          .attr("y1", y1)
          .attr("x2", x2)
          .attr("y2", y2);
      }

      //curve label
      svgCanvas
        .append("text")
        .attr("x", x1)
        .attr("y", y1 - 10)
        .text(d.booked_rate + "%")
        .attr("fill", "black")
        .attr("font-size", "16px")
        .attr("class", "barchart-label")
        .style("stroke", "#d9646f")
        .style("stroke-width", "1px");
    });

    ///////// LEGEND
    let lgd_height = 10;
    let lgd_width = 20;
    let fontSize = "10px";

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#ffd863")
      .attr("x", 50)
      .attr("y", canvasHeight - 20);

    svgCanvas
      .append("text")
      .attr("x", 85)
      .attr("y", canvasHeight - 10)
      .text("Pending quotes")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    ///
    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", Util.COLOR_PALETTE.ECS_MAIN)
      .attr("x", 200)
      .attr("y", canvasHeight - 20);

    svgCanvas
      .append("text")
      .attr("x", 235)
      .attr("y", canvasHeight - 10)
      .text("Other status")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    ////

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#d9646f")
      .attr("x", 350)
      .attr("y", canvasHeight - 20);

    svgCanvas
      .append("text")
      .attr("x", 385)
      .attr("y", canvasHeight - 10)
      .text("Booked ratio")
      .attr("fill", "black")
      .attr("font-size", fontSize);
  }

  draw_rate(data) {
    // PRE-VIZ CALCULATION
    let values = [];
    let { default_currency, exch_rate } = store_analytics.getState();

    data.forEach((q) => {
      let week = new Date(q.time_stamp).getWeek();

      let booked = q.status === "BOOKED" ? 1 : 0;
      let rejected = q.status === "REJECTED" || q.status === "BOOKED" ? 1 : 0;
      let quoted = q.status === "QUOTED" ? 1 : 0;
      let margin = q.margin
      let margin_per_kg = margin && margin.margin_per_kg ? margin.margin_per_kg : {}

      let rate = margin.sell_all_in_rate || margin_per_kg.sell_all_in_rate || 0;
      rate = isNaN(parseFloat(rate)) || !rate ? 0 : parseFloat(rate);

      if (rate && default_currency && default_currency !== q.pricing.currency) {
        rate = Util.convert_currency(
          rate,
          q.pricing.currency,
          default_currency,
          exch_rate
        );
      }

      let weight = q.shipment.chargeable_weight;
      weight = isNaN(parseFloat(weight)) || !weight ? 0 : parseFloat(weight);

      //check if first element of week
      let json = values.filter((r) => r.week === week)[0];
      if (!json) {
        values.push({
          week: week,
          count: 1,
          booked_count: booked,
          booked_rate: weight * booked * rate,
          booked_sum_wgt: booked * weight,
          rejected_rate: weight * rejected * rate,
          rejected_count: rejected,
          rejected_sum_wgt: rejected * weight,
          quoted_rate: weight * quoted * rate,
          quoted_count: quoted,
          quoted_sum_wgt: quoted * weight,
        });
      } else {
        json.count++;
        json.booked_rate += weight * booked * rate;
        json.booked_count += booked;
        json.booked_sum_wgt += booked * weight;

        json.quoted_rate += weight * quoted * rate;
        json.quoted_count += quoted;
        json.quoted_sum_wgt += quoted * weight;

        json.rejected_rate += weight * rejected * rate;
        json.rejected_count += rejected;
        json.rejected_sum_wgt += rejected * weight;
      }
    });

    if (values)
      values.sort(function (a, b) {
        return a.week - b.week;
      });

    values.forEach((v) => {
      v.booked_rate =
        v.booked_rate === 0
          ? 0
          : Math.round((100 * v.booked_rate) / v.booked_sum_wgt) / 100;
      v.quoted_rate =
        v.quoted_rate === 0
          ? 0
          : Math.round((100 * v.quoted_rate) / v.quoted_sum_wgt) / 100;
      v.rejected_rate =
        v.rejected_rate === 0
          ? 0
          : Math.round((100 * v.rejected_rate) / v.rejected_sum_wgt) / 100;
    });

    let maxValue = Math.max.apply(
      null,
      values.map((q) =>
        Math.max.apply(null, [q.quoted_rate, q.booked_rate, q.rejected_rate])
      )
    );
    //CANVAS
    const canvasHeight = 300;
    const canvasWidth =
      document.getElementById("canvas_chart_rate").offsetWidth - 20;
    const canvasMarginY = 30;

    const scale = (canvasHeight - canvasMarginY - 30) / (maxValue * 1.001);

    const svgCanvas = d3
      .select("#chart_rate")
      .append("svg")
      .attr("width", canvasWidth)
      .attr("height", canvasHeight)
      .attr("id", "canvas");

    ///////////// Y AXIS

    let tick = maxValue / 5.0;
    tick = Math.round(10 * tick) / 10;

    for (let i = 0; i < maxValue; i += Math.max(tick, 0.5)) {
      svgCanvas
        .append("line")
        .style("stroke", "lightgrey")
        .style("stroke-width", 1)
        .attr("x1", 0)
        .attr("y1", canvasHeight - i * scale - canvasMarginY)
        .attr("x2", canvasWidth)
        .attr("y2", canvasHeight - i * scale - canvasMarginY);

      svgCanvas
        .append("text")
        .attr("x", 20)
        .attr("y", canvasHeight - i * scale - canvasMarginY)
        .text(i.toFixed(2))
        .attr("fill", "#949494");
    }

    //////////////// X AXIS

    values.forEach((d, i) => {
      //WEEK TICK
      svgCanvas
        .append("text")
        .attr("x", 50 + (i * canvasWidth) / values.length)
        .attr("y", canvasHeight - canvasMarginY + 10)
        .text("W" + d.week)
        .attr("fill", "#949494")
        .attr("class", "barchart-label");
    });

    ///// CURVE HIT RATE
    values.forEach((d, i) => {
      //_______REJECTED
      let x1 = 50 + (i * canvasWidth) / values.length;
      let y1 = canvasHeight - d.rejected_rate * scale - canvasMarginY;

      if (i < values.length - 1) {
        let x2 = 50 + ((i + 1) * canvasWidth) / values.length;
        let y2 =
          canvasHeight - values[i + 1].rejected_rate * scale - canvasMarginY;

        svgCanvas
          .append("line")
          .style("stroke", "#d9646f")
          .style("stroke-width", 2)
          .attr("x1", x1)
          .attr("y1", y1)
          .attr("x2", x2)
          .attr("y2", y2);
      }

      //curve label
      svgCanvas
        .append("text")
        .attr("x", x1 - 30)
        .attr("y", y1 - 10)
        .text(d.rejected_rate.toFixed(2))
        .attr("fill", "#d9646f")
        .attr("font-weight", "bold")
        .attr("class", "barchart-label");

      /// _________ACCEPTED

      x1 = 50 + (i * canvasWidth) / values.length;
      y1 = canvasHeight - d.booked_rate * scale - canvasMarginY;

      if (i < values.length - 1) {
        let x2 = 50 + ((i + 1) * canvasWidth) / values.length;
        let y2 =
          canvasHeight - values[i + 1].booked_rate * scale - canvasMarginY;

        svgCanvas
          .append("line")
          .style("stroke", "#5fa16e")
          .style("stroke-width", 2)
          .attr("x1", x1)
          .attr("y1", y1)
          .attr("x2", x2)
          .attr("y2", y2);
      }

      //curve label
      svgCanvas
        .append("text")
        .attr("x", x1)
        .attr("y", y1 - 10)
        .text(d.booked_rate.toFixed(2))
        .attr("fill", "#5fa16e")
        .attr("font-weight", "bold")
        .attr("class", "barchart-label");

      /// _____________ QUOTED

      x1 = 50 + (i * canvasWidth) / values.length;
      y1 = canvasHeight - d.quoted_rate * scale - canvasMarginY;

      if (i < values.length - 1) {
        let x2 = 50 + ((i + 1) * canvasWidth) / values.length;
        let y2 =
          canvasHeight - values[i + 1].quoted_rate * scale - canvasMarginY;

        svgCanvas
          .append("line")
          .style("stroke", "#edc95c")
          .style("stroke-width", 2)
          .attr("x1", x1)
          .attr("y1", y1)
          .attr("x2", x2)
          .attr("y2", y2);
      }

      //curve label
      svgCanvas
        .append("text")
        .attr("x", x1 + 20)
        .attr("y", y1 - 10)
        .text(d.quoted_rate.toFixed(2))
        .attr("fill", "#ffb94f")
        .attr("font-weight", "bold")
        .attr("class", "barchart-label");
    });

    /////// LEGEND
    let lgd_height = 8;
    let lgd_width = 20;
    let fontSize = "10px";
    let spacing = 140;
    let offsetX = 50;
    let offsetText = 30;
    let offsetY = 15;

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#ffd863")
      .attr("x", offsetX)
      .attr("y", canvasHeight - offsetY);

    svgCanvas
      .append("text")
      .attr("x", offsetX + offsetText)
      .attr("y", canvasHeight - offsetY + lgd_height)
      .text("Quoted")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    ///
    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#5fa16e")
      .attr("x", spacing + offsetX)
      .attr("y", canvasHeight - offsetY);

    svgCanvas
      .append("text")
      .attr("x", spacing + offsetX + offsetText)
      .attr("y", canvasHeight - offsetY + lgd_height)
      .text("Booked")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#d9646f")
      .attr("x", spacing * 2 + offsetX)
      .attr("y", canvasHeight - offsetY);

    svgCanvas
      .append("text")
      .attr("x", spacing * 2 + offsetX + offsetText)
      .attr("y", canvasHeight - offsetY + lgd_height)
      .text("Rejected")
      .attr("fill", "black")
      .attr("font-size", fontSize);
  }

  draw_rejectedQuotes(data) {
    
    let rejectedTab = []; // [ {week : 28, value : 0.1, count : 100, sum}, ...

    // Just Rejected Quotes
    data = data ? data.filter((q) => q.status === "REJECTED") : [];


    console.log("freeReject", data, data.length);

    data.forEach((q) => {
      let week = new Date(q.time_stamp).getWeek();

      let date_week = q.time_stamp;

      let pricingOrRate = q.failure_reason === "Pricing / Rate too high" ? 1 : 0;
      let pastPerformance = q.failure_reason === "Past Performance" ? 1 : 0;
      let otherAgent = q.failure_reason === "Cargo secured by another agent" ? 1 : 0;
      let partUplift = q.failure_reason === "Part Uplift" ? 1 : 0;
      let schedule = q.failure_reason === "Schedule / transit time" ? 1 : 0
      let noOriginCapacity = q.failure_reason === "No available (origin) capacity" ? 1 : 0
      let noFeedback = q.failure_reason === "No feedback from Customer or Client" ? 1 : 0
      let other = q.failure_reason === "Other" ? 1 : 0
      let unknownReason = q.failure_reason === "Unknown reason" ? 1 : 0
      let seaLandMode = q.failure_reason ==="Sea / Land Mode" ? 1 : 0
      let cargoNotLoadable = q.failure_reason === "Cargo Not Loadable" ? 1 : 0
      let gha = q.failure_reason === "GHA/Warehouse in Origin / Destination" ? 1 : 0
      let directService = q.failure_reason === "Preferred Direct Service" ? 1 : 0
      let noTransitCapacity = q.failure_reason === "No available transit capacity" ? 1 : 0
      let lateCommunication = q.failure_reason === "Late Communication / Confirmation" ? 1 : 0 
      
      //check if first element of week
      let json = rejectedTab.filter((r) => r.week === week)[0];
      
      if (json === undefined) {
        rejectedTab.push({
          week: week,
          date_week: date_week,
          count: 1,
          pricingOrRate: pricingOrRate,
          pastPerformance: pastPerformance,
          otherAgent: otherAgent,
          partUplift: partUplift,
          schedule: schedule,
          noOriginCapacity: noOriginCapacity,
          noFeedback: noFeedback,
          other: other,
          unknownReason: unknownReason,
          seaLandMode: seaLandMode,
          cargoNotLoadable: cargoNotLoadable,
          gha: gha,
          directService: directService,
          noTransitCapacity: noTransitCapacity,
          lateCommunication: lateCommunication,
        });
      } else {
        rejectedTab.filter((r) => r.week === week)[0].pricingOrRate += pricingOrRate;
        rejectedTab.filter((r) => r.week === week)[0].count++;
        rejectedTab.filter((r) => r.week === week)[0].pastPerformance += pastPerformance;
        rejectedTab.filter((r) => r.week === week)[0].otherAgent += otherAgent;
        rejectedTab.filter((r) => r.week === week)[0].partUplift += partUplift;
        rejectedTab.filter((r) => r.week === week)[0].schedule += schedule;
        rejectedTab.filter((r) => r.week === week)[0].noOriginCapacity += noOriginCapacity;
        rejectedTab.filter((r) => r.week === week)[0].noFeedback += noFeedback;
        rejectedTab.filter((r) => r.week === week)[0].other += other;
        rejectedTab.filter((r) => r.week === week)[0].unknownReason += unknownReason;
        rejectedTab.filter((r) => r.week === week)[0].seaLandMode += seaLandMode;
        rejectedTab.filter((r) => r.week === week)[0].cargoNotLoadable += cargoNotLoadable;
        rejectedTab.filter((r) => r.week === week)[0].gha += gha;
        rejectedTab.filter((r) => r.week === week)[0].directService += directService;
        rejectedTab.filter((r) => r.week === week)[0].noTransitCapacity += noTransitCapacity;
        rejectedTab.filter((r) => r.week === week)[0].lateCommunication += lateCommunication;
      }
    });


    if (rejectedTab)
      rejectedTab.sort(function (a, b) {
        return a.week - b.week;
      });

    console.log("filtering", rejectedTab);

    let maxCount = Math.max.apply(
      null,
      rejectedTab.map((q) => q.count)
    );

    //CANVAS
    const canvasHeight = 420;
    const canvasWidth =
      document.getElementById("canvas_rejected_quotes").offsetWidth - 20;
    let canvasMarginY = 150;

    const scaleR = (canvasHeight - canvasMarginY - 20) / (maxCount * 1.001);


    //const keys = Object.keys(rejectedTab[0]).filter(d => d !== "week");

    // Create SVG canvas
    const svgCanvas = d3
      .select("#rejected_quotes")
      .append("svg")
      .attr("width", canvasWidth)
      .attr("height", canvasHeight)
      .attr("id", "canvas");

    /////////////: AXIS

    let tick = maxCount / 5;

    for (
      let i = 0;
      i < maxCount;
      i += Math.max(Math.round(tick / 50) * 50, 10)
    ) {
      svgCanvas
        .append("line")
        .style("stroke", "lightgrey")
        .style("stroke-width", 1)
        .attr("x1", 0)
        .attr("y1", canvasHeight - canvasMarginY - i * scaleR)
        .attr("x2", canvasWidth)
        .attr("y2", canvasHeight - canvasMarginY - i * scaleR);

      svgCanvas
        .append("text")
        .attr("x", 20)
        .attr("y", canvasHeight - canvasMarginY - i * scaleR + 10)
        .text(i)
        .attr("fill", "#949494");
    }

    svgCanvas
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", -(canvasHeight - canvasMarginY) / 2)
      .attr("y", 10)
      .attr("transform", "rotate(-90)")
      .text("Total Number of Quotes")
      .attr("fill", "#949494")
      .attr("class", "barchart-label");

    let bar_width = 25;

    rejectedTab.forEach((d, i) => {
      let yCurrent = 0;
      
      svgCanvas
        .append("text")
        .attr("x", 2 + 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", canvasHeight - canvasMarginY - 10 - d.count * scaleR)
        .text(d.count)
        .attr("fill", Util.COLOR_PALETTE.ECS_MAIN)
        .attr("class", "barchart-label");

      //WEEK TICK
      svgCanvas
        .append("text")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", 15 + canvasHeight - canvasMarginY)
        .text("W" + d.week)
        .attr("fill", "#949494")
        .attr("class", "barchart-label");

      //HISTOGRAMME
      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.pricingOrRate * scaleR)
        .attr("fill", "#ffd863")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent = canvasHeight - canvasMarginY - d.pricingOrRate * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.pastPerformance * scaleR)
        .attr("fill", Util.COLOR_PALETTE.ECS_MAIN)
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.pastPerformance * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.otherAgent * scaleR)
        .attr("fill", "#d06a71")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.otherAgent * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.partUplift * scaleR)
        .attr("fill", "#DD7829")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.partUplift * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.schedule * scaleR)
        .attr("fill", "#2989E3")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.schedule * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.noOriginCapacity * scaleR)
        .attr("fill", "#0CF869")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.noOriginCapacity * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.noFeedback * scaleR)
        .attr("fill", "#F8370C")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.noFeedback * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.unknownReason * scaleR)
        .attr("fill", "#7D5718")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.unknownReason * scaleR));

       svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.other * scaleR)
        .attr("fill", "#29E3DE")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.other * scaleR));
        
      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.seaLandMode * scaleR)
        .attr("fill", "#C7CBC8")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.seaLandMode * scaleR));
                
      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.cargoNotLoadable * scaleR)
        .attr("fill", "#FEE1BD")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.cargoNotLoadable * scaleR));
                      
      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.gha * scaleR)
        .attr("fill", "#FEBDD5")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.gha * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.directService * scaleR)
        .attr("fill", "#A82DC6")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.directService * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.noTransitCapacity * scaleR)
        .attr("fill", "#808989")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.noTransitCapacity * scaleR));

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.lateCommunication * scaleR)
        .attr("fill", "#2FAE1D")
        .attr("x", 50 + (i * canvasWidth) / rejectedTab.length)
        .attr("y", (yCurrent -= d.lateCommunication * scaleR));
    });

     ///// CURVE HIT RATE

    ///////// LEGEND
    let lgd_height = 10;
    let lgd_width = 20;
    let fontSize = "10px";

    /** 1st line */
    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#ffd863")
      .attr("x", 50)
      .attr("y", canvasHeight - 110);

    svgCanvas
      .append("text")
      .attr("x", 75)
      .attr("y", canvasHeight - 100)
      .text("Pricing/Rate too High")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", Util.COLOR_PALETTE.ECS_MAIN)
      .attr("x", 190)
      .attr("y", canvasHeight - 110);

    svgCanvas
      .append("text")
      .attr("x", 215)
      .attr("y", canvasHeight - 100)
      .text("Past performance")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#d06a71")
      .attr("x", 350)
      .attr("y", canvasHeight - 110);

    svgCanvas
      .append("text")
      .attr("x", 375)
      .attr("y", canvasHeight - 100)
      .text("Cargo secured by another agent")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#DD7829")
      .attr("x", 560)
      .attr("y", canvasHeight - 110);

    svgCanvas
      .append("text")
      .attr("x", 585)
      .attr("y", canvasHeight - 100)
      .text("Part Uplift")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    /** 2nd line */
    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#2989E3")
      .attr("x", 50)
      .attr("y", canvasHeight - 80);

    svgCanvas
      .append("text")
      .attr("x", 75)
      .attr("y", canvasHeight - 70)
      .text(" Schedule / transit time ")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#0CF869")
      .attr("x", 190)
      .attr("y", canvasHeight - 80);

    svgCanvas
      .append("text")
      .attr("x", 215)
      .attr("y", canvasHeight - 70)
      .text("No available (origin) capacity")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#F8370C")
      .attr("x", 350)
      .attr("y", canvasHeight - 80);

    svgCanvas
      .append("text")
      .attr("x", 375)
      .attr("y", canvasHeight - 70)
      .text("No feedback from Customer or client")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#7D5718")
      .attr("x", 560)
      .attr("y", canvasHeight - 80);

    svgCanvas
      .append("text")
      .attr("x", 585)
      .attr("y", canvasHeight - 70)
      .text("Unknown reason")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#C7CBC8")
      .attr("x", 50)
      .attr("y", canvasHeight - 50);

    /** 3rd line */
    svgCanvas
      .append("text")
      .attr("x", 75)
      .attr("y", canvasHeight - 40)
      .text("Sea land mode ")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#FEE1BD")
      .attr("x", 190)
      .attr("y", canvasHeight - 50);

    svgCanvas
      .append("text")
      .attr("x", 215)
      .attr("y", canvasHeight - 40)
      .text("Cargo Not Loadable")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#FEBDD5")
      .attr("x", 350)
      .attr("y", canvasHeight - 50);

    svgCanvas
      .append("text")
      .attr("x", 375)
      .attr("y", canvasHeight - 40)
      .text("GHA/Warehouse in Origin / Destination ")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#29E3DE")
      .attr("x", 560)
      .attr("y", canvasHeight - 50);

    svgCanvas
      .append("text")
      .attr("x", 585)
      .attr("y", canvasHeight - 40)
      .text("Other")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#A82DC6")
      .attr("x", 50)
      .attr("y", canvasHeight - 20);

    svgCanvas
      .append("text")
      .attr("x", 75)
      .attr("y", canvasHeight - 10)
      .text("Preferred direct service ")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#808989")
      .attr("x", 190)
      .attr("y", canvasHeight - 20);

    svgCanvas
      .append("text")
      .attr("x", 215)
      .attr("y", canvasHeight - 10)
      .text("No available transit capacity")
      .attr("fill", "black")
      .attr("font-size", fontSize);

    svgCanvas
      .append("rect")
      .attr("width", lgd_width)
      .attr("height", lgd_height)
      .attr("fill", "#2FAE1D")
      .attr("x", 350)
      .attr("y", canvasHeight - 20);

    svgCanvas
      .append("text")
      .attr("x", 375)
      .attr("y", canvasHeight - 10)
      .text("Late Communication/Confirmation")
      .attr("fill", "black")
      .attr("font-size", fontSize);
  }


  draw_quoteByStatus(data) {
    if (!data) return null;
    data = data.filter(
      (q) =>
        Math.round(
          (new Date() - new Date(q.time_stamp)) / (1000 * 3600 * 24)
        ) <= store_analytics.getState().filters.delay
    );

    data = [
      {
        name: "QUOTED",
        color: "#edc95c",
        value: data.filter((d) => d.status === "QUOTED").length,
      },
      //{ name : "ACCEPTED", color:   "#5fa16e", value :  data.filter((d) => d.status === "ACCEPTED").length  },
      {
        name: "BOOKED",
        color: "#509ebf",
        value: data.filter((d) => d.status === "BOOKED").length,
      },
      {
        name: "REJECTED",
        color: "#d9646f",
        value: data.filter((d) => d.status === "REJECTED").length,
      },
      {
        name: "CANCELLED",
        color: "#d9646f",
        value: data.filter((d) => d.status === "CANCELLED").length,
      },
      {
        name: "EXPIRED",
        color: Util.STATUS.filter((s) => s.name === "EXPIRED")[0].color,
        value: data.filter((d) => d.status === "EXPIRED").length,
      },
    ];

    //CHART

    let fontSize = "10px";
    let canvasWidth =
      document.getElementById("canvas_chart_quote_status").offsetWidth - 10;
    let canvasHeight = 250;
    let canvasMarginY = 20;
    let canvasMarginX = 50;

    let maxValue = Math.max.apply(
      null,
      data.map((d) => d.value)
    );
    let scale = (canvasHeight - canvasMarginY - 30) / (maxValue * 1.001);
    let bar_width = 20;

    var svgCanvas = d3
      .select("#chart_quote_status")
      .append("svg")
      .attr("width", canvasWidth)
      .attr("height", canvasHeight);

    ///////// Y-AXIS
    const tick = maxValue / 5;

    for (
      let i = 0;
      i < maxValue;
      i += Math.max(Math.round(tick / 10) * 10, 1)
    ) {
      svgCanvas
        .append("line")
        .style("stroke", "lightgrey")
        .style("stroke-width", 1)
        .attr("x1", 0)
        .attr("y1", canvasHeight - i * scale - canvasMarginY)
        .attr("x2", canvasWidth)
        .attr("y2", canvasHeight - i * scale - canvasMarginY);

      svgCanvas
        .append("text")
        .attr("x", 20)
        .attr("y", canvasHeight - i * scale - canvasMarginY)
        .text(i)
        .attr("fill", "#949494");
    }

    //legend

    data.forEach((d, i) => {
      //VALUE
      svgCanvas
        .append("text")
        .attr("x", canvasMarginX + (i * canvasWidth) / data.length)
        .attr("y", canvasHeight - canvasMarginY - 10 - d.value * scale)
        .text(d.value)
        .attr("fill", "black")
        .attr("class", "barchart-label");

      // X AXIS
      svgCanvas
        .append("text")
        .attr(
          "x",
          canvasMarginX + (i * canvasWidth) / data.length - bar_width / 2
        )
        .attr("y", canvasHeight)
        .text(d.name)
        .attr("fill", "black")
        .attr("class", "barchart-label")
        .attr("font-size", fontSize);

      svgCanvas
        .append("rect")
        .attr("width", bar_width)
        .attr("height", d.value * scale)
        .attr("fill", d.color)
        .attr("x", canvasMarginX + (i * canvasWidth) / data.length)
        .attr("y", canvasHeight - canvasMarginY - d.value * scale);
    });
  }

  componentDidUpdate() {
    //DRAW CHART AFTER RENDERING
    let analytics_state = store_analytics.getState();
    let data = analytics_state.data;

    if (!data) return null;
    this.draw_rate(data.quotes);
    this.draw_hit_rate(data.quotes);
    this.draw_quoteByStatus(data.quotes);
    this.draw_rejectedQuotes(data.quotes);
    
  }

  async handlePDF(e) {
    e.preventDefault();

    store_analytics.dispatch({ type: 1, page_loading_spinner: true });
    let doc = document.getElementById("screen_analytics");

    //ajuster style de la page pour que ça ressorte bien sur le pdf
    doc.style.paddingRight = "48px";
    doc.style.paddingTop = "48px";
    let filters = document.getElementById("analytics-filters");
    filters.hidden = true;

    let table_pending = document.getElementById("table_pending");
    table_pending.style.fontSize = "0.7em";

    var pdf = null;

    //separate in 2 pages
    let section = document.getElementById("row_status");
    let graph_status = document.getElementById("canvas_chart_quote_status");
    console.log("graph_status", graph_status);

    //let img_graph = await Util.html2img(graph_status)
    //section.removeChild(graph_status)
    let img_main = await Util.html2img(doc);

    pdf = await Util.img2pdf(img_main, null);
    //pdf = await Util.img2pdf(img_graph, pdf)

    //SAVE
    pdf.save(
      "Analytics_" +
        cookies.station +
        "_" +
        Util.convertDate(new Date(), null, "")
    );

    //on rétablit le style initial
    doc.style.paddingRight = "";
    doc.style.paddingTop = "";
    filters.hidden = false;
    table_pending.style.fontSize = "";
    section.appendChild(graph_status);
    store_analytics.dispatch({ type: 1, page_loading_spinner: false });

    let logs = {
      type: "EXPORT_ANALYTICS_PDF",
    };
    await Routes.get("/Logger", logs, true);
  }

  render() {
    if (!this.props.loggedIn|| !cookies.rights ||(cookies.rights && !cookies.rights.analytics_view)) {
      return window.location.replace(config.front_host);
    }

    let analytics_state = store_analytics.getState();
    let data = analytics_state.data;

    if (!data) return <PageLoadingSpinner />;

    let commodities = this.props.shcList
      ? Util.deduplicate(this.props.shcList.map((c) => c.commodity_name))
      : [];
    let carriers_list = this.props.carriers_list.map((c) => c.code);

    const filters = analytics_state.filters;

    return (
      <div key={this.state.key} class="screen_analytics" id="screen_analytics">
        <PageLoadingSpinner />

        {analytics_state.alert && (
          <Alert type="error" title="Error" msg={analytics_state.alert} />
        )}

        <div class="container-fluid background">
          <div class="card-body graph-background">
            <div class="row analytics-filters" id="analytics-filters">
              <div class="col-lg-1 col-sm-4">
                <button
                  class="btn btn-primary"
                  data-tip="Refresh"
                  data-type="info"
                  style={{ marginTop: "2em", marginRight: "0.5em" }}
                  onClick={(e) => this.loadData(e)}
                >
                  <Refresh fontSize="small" />
                </button>

                <button
                  style={{ marginTop: "2em" }}
                  class="btn btn-success"
                  data-tip="Download"
                  data-type="info"
                  onClick={this.handlePDF}
                >
                  <GetAppIcon fontSize="small" />
                </button>
              </div>

              <div class="col-lg-1 col-sm-4">
                <FormControlLabel
                  style={{ marginTop: "0.5em" }}
                  control={
                    <Switch
                      color="secondary"
                      data-tip={
                        analytics_state.filters.only_user
                          ? "Show all users"
                          : "Show my quotes"
                      }
                      checked={analytics_state.filters.only_user}
                      onChange={(e) => {
                        store_analytics.dispatch({
                          type: 1,
                          filters: true,
                          only_user: !analytics_state.filters.only_user,
                        });
                        this.loadData();
                      }}
                      name="showonly"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="Only my quotes"
                  labelPlacement="top"
                />
              </div>

              <div class="col-lg-1 col-sm-4">
                <FormControlLabel
                  style={{ marginTop: "0.5em" }}
                  control={
                    <Switch
                      color="secondary"
                      data-tip={
                        analytics_state.filters.follow_up
                          ? "Show all Quotes"
                          : "Show following"
                      }
                      checked={filters.follow_up}
                      onChange={(e) => {
                        store_analytics.dispatch({
                          type: 1,
                          filters: true,
                          follow_up: !filters.follow_up,
                        });
                        this.loadData();
                      }}
                      name="followedUp"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label="To Follow up"
                  labelPlacement="top"
                />
              </div>

              <div class="col-lg-2 col-sm-4">
                <label>Carrier</label>
                <Autocomplete
                  multiple
                  defaultValue={analytics_state.filters.carrier}
                  id="autocomplete_carrier"
                  class="form-group"
                  popupIcon={null}
                  disableCloseOnSelect={false}
                  fullWidth
                  style={{ width: "100%", marginBottom: 15 }}
                  options={carriers_list}
                  //getOptionLabel={option => option.name}
                  noOptionsText=""
                  onBlur={async (e) => {
                    let v = document
                      .getElementById("autocomplete_carrier")
                      .value.split(" - ");
                    v = v[0].split(" ");
                    store_analytics.dispatch({
                      type: 1,
                      filters: true,
                      carrier: v,
                    });
                    this.loadData();
                  }}
                  onKeyUp={async (e) => {
                    let v = document
                      .getElementById("autocomplete_carrier")
                      .value.split(" - ");
                    v = v[0].split(" ");
                    if (e.key === "Enter") {
                      store_analytics.dispatch({
                        type: 1,
                        filters: true,
                        carrier: v,
                      });
                      this.loadData();
                    }
                  }}
                  onChange={async (e, v) => {
                    store_analytics.dispatch({
                      type: 1,
                      filters: true,
                      carrier2: v,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      class="form-control"
                      style={{ height: "100%", marginBottom: "5px" }}
                      {...params}
                      placeholder=""
                      fullWidth
                    />
                  )}
                />
              </div>

              <div class="col-lg-3 col-sm-8">
                <label>Agent</label>
                <AutocompleteAgent />
              </div>

              <div class="col-lg-1 col-sm-2">
                <label>Origin</label>
                <Autocomplete
                  multiple
                  defaultValue={analytics_state.filters.origin}
                  id="autocomplete_origin"
                  class="form-group"
                  popupIcon={null}
                  disableCloseOnSelect={false}
                  fullWidth
                  style={{ width: "100%", marginBottom: 15 }}
                  options={[]}
                  noOptionsText=""
                  onBlur={async (e) => {
                    let v = document
                      .getElementById("autocomplete_origin")
                      .value.split(" - ");
                    v = v[0].split(" ");
                    store_analytics.dispatch({
                      type: 1,
                      filters: true,
                      origin: v,
                    });
                    this.loadData();
                  }}
                  onKeyUp={async (e) => {
                    let v = document
                      .getElementById("autocomplete_origin")
                      .value.split(" - ");
                    v = v[0].split(" ");
                    if (e.key === "Enter") {
                      store_analytics.dispatch({
                        type: 1,
                        filters: true,
                        origin: v,
                      });
                      this.loadData();
                    }
                  }}
                  onChange={async (e, v) => {
                    store_analytics.dispatch({
                      type: 1,
                      filters: true,
                      origin2: v,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      class="form-control"
                      style={{ height: "100%", marginBottom: "5px" }}
                      {...params}
                      placeholder=""
                      fullWidth
                    />
                  )}
                />
              </div>

              <div class="col-lg-1 col-sm-2">
                <label>Destination</label>
                <Autocomplete
                  multiple
                  defaultValue={analytics_state.filters.dest}
                  id="autocomplete_dest"
                  class="form-group"
                  popupIcon={null}
                  disableCloseOnSelect={false}
                  fullWidth
                  style={{ width: "100%", marginBottom: 15 }}
                  options={[]}
                  noOptionsText=""
                  onBlur={async (e) => {
                    let v = document
                      .getElementById("autocomplete_dest")
                      .value.split(" - ");
                    v = v[0].split(" ");
                    store_analytics.dispatch({
                      type: 1,
                      filters: true,
                      dest: v,
                    });
                    this.loadData();
                  }}
                  onKeyUp={async (e) => {
                    let v = document
                      .getElementById("autocomplete_dest")
                      .value.split(" - ");
                    v = v[0].split(" ");

                    if (e.key === "Enter") {
                      store_analytics.dispatch({
                        type: 1,
                        filters: true,
                        dest: v,
                      });
                      this.loadData();
                    }
                  }}
                  onChange={async (e, v) => {
                    store_analytics.dispatch({
                      type: 1,
                      filters: true,
                      dest2: v,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      class="form-control"
                      style={{ height: "100%", marginBottom: "5px" }}
                      {...params}
                      placeholder=""
                      fullWidth
                    />
                  )}
                />
              </div>

              <div class="col-lg-2 col-sm-4">
                <label>Commodity Name</label>
                <Autocomplete
                  multiple
                  defaultValue={analytics_state.filters.commodity}
                  id="autocomplete_commodity"
                  class="form-group"
                  popupIcon={null}
                  disableCloseOnSelect={false}
                  fullWidth
                  style={{ width: "100%", marginBottom: 15 }}
                  options={commodities}
                  noOptionsText=""
                  onBlur={async (e) => {
                    let v = document
                      .getElementById("autocomplete_commodity")
                      .value.split(" - ");
                    store_analytics.dispatch({
                      type: 1,
                      filters: true,
                      commodity: v[0].split(" "),
                    });
                    this.loadData();
                  }}
                  onKeyUp={async (e) => {
                    let v = document
                      .getElementById("autocomplete_commodity")
                      .value.split(" - ");
                    if (e.key === "Enter") {
                      store_analytics.dispatch({
                        type: 1,
                        filters: true,
                        commodity: v[0].split(" "),
                      });
                      this.loadData();
                    }
                  }}
                  onChange={async (e, v) => {
                    store_analytics.dispatch({
                      type: 1,
                      filters: true,
                      commodity2: v,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      class="form-control"
                      style={{ height: "100%", marginBottom: "5px" }}
                      {...params}
                      placeholder=""
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>

            <br />

            <div class="row">
              <div
                class="col-lg-5 col-sm-12 analytics-section-1"
                id="analytics-section-1"
              >
                <h3 class="analytics-title">
                  {" "}
                  <img src={require("../img/speed.png")} alt="" /> Recent Quotes{" "}
                </h3>
                <div class="row">
                  <div class="col-4">
                    <span class="slider_delay">
                      <label>Age (days)</label>
                      <Slider
                        id="slider_delay"
                        max={30}
                        min={1}
                        defaultValue={analytics_state.filters.delay}
                        valueLabelDisplay="on"
                        step={1}
                        marks={[
                          { value: 7, label: "" },
                          { value: 14, label: "" },
                          { value: 21, label: "" },
                          { value: 30, label: "30" },
                        ]}
                        onMouseUp={(e) => {
                          let val = document
                            .getElementById("slider_delay")
                            .getElementsByTagName("input")[0].value;
                          store_analytics.dispatch({
                            type: 1,
                            filters: true,
                            delay: val,
                          });
                        }}
                      />
                    </span>
                  </div>
                </div>

                <div class="row">
                  <TablePending />
                </div>

                <div class="row" id="row_status">
                  <div class="col-11 graph" id="canvas_chart_quote_status">
                    <h3>
                      {" "}
                      <img src={require("../img/kpi.png")} alt="" /> Quotes per
                      Status{" "}
                    </h3>
                    <hr />
                    <div id="chart_quote_status"></div>
                  </div>
                </div>
              </div>

              <div class="col-lg-7 col-sm-12 analytics-section-3">
                <h3 class="analytics-title">
                  {" "}
                  <img
                    src={require("../img/target.png")}
                    alt=""
                  /> Performance{" "}
                </h3>
                <div class="row">
                  <div class="col-lg-4 col-sm-12">
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {<ComparisonBoard />}
                    <br />
                    <br />
                    <div
                      class="row"
                      style={{ background: "#f8f9fa", margin: "0.5em" }}
                    >
                      <div class="col-6" style={{ padding: "0.5em" }}>
                        <div style={{ background: "white" }}>
                          <img
                            alt="Apollo"
                            height="50%"
                            width="90%"
                            src={require("../img/apollo_logo.jpg")}
                          />
                        </div>
                      </div>

                      <div class="col-6" style={{ padding: "0.5em" }}>
                        <div>
                          Click{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://app.powerbi.com/groups/me/apps/215964b1-1196-47d0-bbe6-713d8ecd1dde/reports/d66ed13d-51ba-4e73-9d8b-760a9da2a2eb/ReportSectionbe03077c21e3e44cabfe"
                          >
                            here
                          </a>{" "}
                          to access Quantum Dashboards on Apollo
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8 col-sm-12">
                    <div class="row">
                      <div class="col-12 graph" id="canvas_chart_hit_rate">
                        <h3>
                          {" "}
                          <img src={require("../img/kpi.png")} alt="" /> Hit
                          Ratio and Number of Quotes{" "}
                        </h3>
                        <hr />
                        <div id="chart_hit_rate"></div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 graph" id="canvas_chart_rate">
                        <h3>
                          {" "}
                          <img src={require("../img/kpi.png")} alt="" /> Average
                          Rate per Quote Status{" "}
                        </h3>
                        <hr />
                        <div id="chart_rate"></div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 graph" id="canvas_rejected_quotes">
                        <h3>
                          {" "}
                          <img src={require("../img/kpi.png")} alt="" />{" "}
                          Rejected Quotes Analysis{" "}
                        </h3>
                        <hr />
                        <div id="rejected_quotes"> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    );
  }
}

export default connect((state) => ({
  carriers_list: state.carriers_list,
  shcList: state.shcList,
  custom_list: state.custom_list,
  loggedIn: state.loggedIn,
  exch_rate: state.exch_rate,
}))(Main);

class ComparisonBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {
    let w1 = watch(store_analytics.getState, "data");
    store_analytics.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let analytics_state = store_analytics.getState();
    let default_currency = analytics_state.default_currency;
    let exch_rate = analytics_state.exch_rate;
    let quotes = analytics_state.data.quotes;

    let current_week = new Date().getWeek();
    let quotes_now = quotes.filter(
      (q) => current_week === new Date(q.time_stamp).getWeek()
    );
    let quotes_month = quotes.filter(
      (q) => current_week !== new Date(q.time_stamp).getWeek()
    );

    let weeks = 4;
    let kpi = {
      now_count: quotes_now.length,
      month_count: Math.round(quotes_month.length / weeks),
      now_avg_rate: 0,
      now_sum_wgt: 0,
      month_avg_rate: 0,
      month_sum_wgt: 0,
      now_booked: 0,
      month_booked: 0,
    };

    /////calculate KPI now
    quotes_now.forEach((q, i) => {
      if (q.status === "BOOKED") kpi.now_booked++;

      let margin = q.margin
      let margin_per_kg = margin && margin.margin_per_kg ? margin.margin_per_kg : {}


      let rate = margin.sell_all_in_rate || margin_per_kg.sell_all_in_rate || 0;
      rate = isNaN(parseFloat(rate)) ? 0 : parseFloat(rate);

      if (rate && default_currency && default_currency !== q.pricing.currency) {
        rate = Util.convert_currency(
          rate,
          q.pricing.currency,
          default_currency,
          exch_rate
        );
      }

      let weight = q.shipment.chargeable_weight;
      weight = isNaN(parseFloat(weight)) ? 0 : parseFloat(weight);

      kpi.now_avg_rate += rate * weight;
      kpi.now_sum_wgt += weight;
    });

    kpi.now_avg_rate = kpi.now_avg_rate / (kpi.now_sum_wgt * 1.0);
    kpi.now_booked =
      Math.round((100 * 10 * kpi.now_booked) / kpi.now_count) / 10;
    kpi.now_booked = kpi.now_booked ? kpi.now_booked : 0;

    kpi.now_avg_rate = isNaN(parseFloat(kpi.now_avg_rate))
      ? 0
      : parseFloat(kpi.now_avg_rate);
    kpi.now_avg_rate = Math.round(100 * kpi.now_avg_rate) / 100;
    kpi.now_avg_rate = kpi.now_avg_rate ? kpi.now_avg_rate : 0;

    kpi.now_booked = isNaN(parseFloat(kpi.now_booked)) ? 0 : kpi.now_booked;

    /////calculate KPI 5 WEEK
    quotes_month.forEach((q, i) => {
      if (q.status === "BOOKED") kpi.month_booked++;

      let margin = q.margin
      let margin_per_kg = margin && margin.margin_per_kg ? margin.margin_per_kg : {}
      let rate = margin.sell_all_in_rate || margin_per_kg.sell_all_in_rate || 0;
      rate = isNaN(parseFloat(rate)) || !rate ? 0 : parseFloat(rate);

      if (rate && default_currency && default_currency !== q.pricing.currency) {
        rate = Util.convert_currency(
          rate,
          q.pricing.currency,
          default_currency,
          exch_rate
        );
      }

      let weight = q.shipment.chargeable_weight;
      weight = isNaN(parseFloat(weight)) || !weight ? 0 : parseFloat(weight);

      kpi.month_avg_rate += rate * weight;
      kpi.month_sum_wgt += weight;
    });

    kpi.month_avg_rate =
      Math.round((100 * kpi.month_avg_rate) / (kpi.month_sum_wgt * 1.0)) / 100;
    kpi.month_avg_rate = kpi.month_avg_rate ? kpi.month_avg_rate : 0;
    kpi.month_booked =
      Math.round((100 * kpi.month_booked) / kpi.month_count) / weeks;
    kpi.month_booked = kpi.month_booked ? kpi.month_booked : 0;

    return (
      <div class="row">
        <div class="col-6">
          <h4 data-tip="Showing data as from Monday of the current week">
            {" "}
            Current Week ({current_week}) <QuestionMark />{" "}
          </h4>
        </div>

        <div class="col-6">
          <h4>Avg Last 4 Weeks</h4>
        </div>

        <div class="col-6">
          <div class=" showcase_number">
            <strong>{kpi.now_count}</strong>
            <br />
            Quotes
          </div>
        </div>

        <div class="col-6">
          <div class=" showcase_number">
            <strong>{kpi.month_count}</strong>
            <br />
            Quotes/Week
          </div>
        </div>

        <div class="col-6">
          <div class=" showcase_number">
            <strong>
              {parseFloat(kpi.now_avg_rate).toFixed(2)}{" "}
              {default_currency ? default_currency + "/kg" : ""}
            </strong>
            <br />
            Avg. Rate
          </div>
        </div>

        <div class="col-6">
          <div class=" showcase_number">
            <strong>
              {parseFloat(kpi.month_avg_rate).toFixed(2)}{" "}
              {default_currency ? default_currency + "/kg" : ""}{" "}
            </strong>
            <br />
            Avg. Rate
          </div>
        </div>

        <div class="col-6">
          <div class=" showcase_number">
            <strong>{parseFloat(kpi.now_booked).toFixed(1)}%</strong>
            <br />
            Booked
          </div>
        </div>

        <div class="col-6">
          <div class=" showcase_number">
            <strong>{parseFloat(kpi.month_booked).toFixed(1)}%</strong>
            <br />
            Booked/Week
          </div>
        </div>
      </div>
    );
  }
}
class TablePending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {
    let w1 = watch(store_analytics.getState, "data");
    store_analytics.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w2 = watch(store_analytics.getState, "filters.pending_sort");
    store_analytics.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let analytics_state = store_analytics.getState();
    let data = analytics_state.data;

    if (!data) return null;

    data = data.quotes.filter(
      (q) =>
        q.status === "QUOTED" &&
        Math.round(
          (new Date() - new Date(q.time_stamp)) / (1000 * 3600 * 24)
        ) <= analytics_state.filters.delay
    );

    data = _.sortBy(data, [
      function (q) {
        let margin = q.margin
        let margin_in_total = margin && margin.margin_in_total ? margin.margin_in_total : {}

        let marginTotal = margin.marginTotal || margin_in_total.margin || 0

        if (analytics_state.filters.pending_sort === "revenue")
          return -marginTotal;
        else if (analytics_state.filters.pending_sort === "recent")
          return -Date.parse(q.time_stamp);
        else return Date.parse(q.time_stamp);
      },
    ]);

    //formatting table lines
    let html = data.slice(0, 15).map((q) => {
      let shipment = q.shipment;
      let pricing = q.pricing;
      let margin = q.margin;
      
      let margin_per_kg = margin && margin.margin_per_kg ? margin.margin_per_kg : {}
      let margin_in_total = margin && margin.margin_in_total ? margin.margin_in_total : {}

      let sell_all_in_rate =  margin.sell_all_in_rate || margin_per_kg.sell_all_in_rate || 0
      let marginTotal =  margin.marginTotal ||  margin_in_total.margin || 0 

      let age = new Date() - new Date(q.time_stamp);
      age = Math.round(age / (1000 * 3600 * 24)); //converting to days
      return (
        <tr>
          <td>
            <a
               target="_blank" 
               href={"#/Editor/" + q.id}
               rel="noopener noreferrer"
               >
              {q.id}
            </a>
          </td>
          <td>{age}</td>
          <td>{pricing.carrier}</td>
          <td>{shipment.origin}</td>
          <td>{shipment.dest}</td>
          <td>{shipment.agent}</td>
          <td>
            {shipment.chargeable_weight
              ? Util.formatAmount4(shipment.chargeable_weight)
              : shipment.chargeable_weight}
          </td>
          <td>{pricing.currency}</td>
          <td>
            {sell_all_in_rate
              ? parseFloat(sell_all_in_rate).toFixed(2)
              : sell_all_in_rate}
          </td>
          <td>{Util.formatAmount3(Math.round(marginTotal))}</td>
        </tr>
      );
    });

    let pending_sort = analytics_state.filters.pending_sort;
    return (
      <div class="col-11 graph" key={Date.now()} id="table_pending">
        <h3>
          <img src={require("../img/kpi.png")} alt="" />
          <span data-tip="Pending quotes : quotes with status  QUOTED">
            {" "}
            TOP {data.length < 15 ? data.length : 15} Pending Quotes{" "}
            <QuestionMark />{" "}
          </span>
          <span>(Total {data.length}) </span>
          <ReactTooltip type="dark" effect="solid" />
        </h3>

        <hr />

        <span>
          {pending_sort === "revenue" ? (
            <strong>Sort By Expected Margin</strong>
          ) : (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                store_analytics.dispatch({
                  type: 1,
                  filters: true,
                  pending_sort: "revenue",
                });
              }}
            >
              Sort By Expected Margin
            </a>
          )}

          {" / "}

          {pending_sort === "date" ? (
            <strong>Less recent </strong>
          ) : (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                store_analytics.dispatch({
                  type: 1,
                  filters: true,
                  pending_sort: "date",
                });
              }}
            >
              Less recent
            </a>
          )}

          {" / "}

          {pending_sort === "recent" ? (
            <strong>More recent </strong>
          ) : (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                store_analytics.dispatch({
                  type: 1,
                  filters: true,
                  pending_sort: "recent",
                });
              }}
            >
              More recent
            </a>
          )}
        </span>

        <div>
          <table class="table table-striped small-table">
            <thead class="thead-light ">
              <tr class="small_font">
                <th>ID</th>
                <th data-tip="Age : days past since quote creation">
                  Age* (days)
                  <ReactTooltip place="top" type="dark" effect="solid" />
                </th>
                <th>Carrier</th>
                <th>Origin</th>
                <th>Dest</th>
                <th>Agent</th>
                <th>Chg. Wgt.</th>
                <th>Curr.</th>
                <th>All-in/kg</th>
                <th>Expected Margin</th>
              </tr>
            </thead>

            <tbody>{html}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: props.type,
      title: props.title,
      msg: props.msg,
    };
  }

  render() {
    let html = [];
    let props = this.state;

    let classValue = "warning";
    if (props.type === "warning") classValue = "alert alert-warning";
    else if (props.type === "success") classValue = "alert alert-success";
    else if (props.type === "error") classValue = "alert alert-danger";

    html = (
      <div class={classValue} role="alert">
        <h4 class="alert-heading">{props.title}</h4>
        <p>{props.msg}</p>
      </div>
    );

    return html;
  }
}

class PageLoadingSpinner extends React.Component {
  constructor(props) {
    super(props);

    this.state = { key: Date.now() };

    this.style = {
      position: "absolute",
      left: "50%",
      margin: "auto",
      "margin-top": "20em",
      "z-index": "10",
      color: "white",
    };
  }

  componentDidMount() {
    let w1 = watch(store_analytics.getState, "page_loading_spinner");
    store_analytics.subscribe(
      w1((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    let show = store_analytics.getState().page_loading_spinner;

    if (!show) return null;
    else {
      return (
        <div key={this.state} style={this.style} class="" role="status">
          <Backdrop open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      );
    }
  }
}

class QuestionMark extends React.Component {
  render() {
    return <img src={require("../img/question.png")} alt="" />;
  }
}
class AutocompleteAgent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: Date.now(),
    };
  }

  componentDidMount() {
    let w2 = watch(store_analytics.getState, "agent_suggestion");
    store_analytics.subscribe(
      w2((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );

    let w3 = watch(store_analytics.getState, "modalAgent");
    store_analytics.subscribe(
      w3((newVal, oldVal, objectPath) => {
        //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        if (oldVal !== newVal) this.setState({ key: Date.now() });
      })
    );
  }

  render() {
    console.log("[RENDER] Autocomplete");

    let analytics_state = store_analytics.getState();

    return (
      <Autocomplete
        id="autocomplete_agent_1"
        options={analytics_state.agent_suggestion}
        noOptionsText=""
        renderInput={(params) => {
          return (
            <div>
              <TextField
                {...params}
                class="form-control"
                margin="normal"
                placeholder={analytics_state.filters.agent}
                fullWidth
              />
            </div>
          );
        }}
        onKeyUp={async (e) => {
          if (
            e.keyCode !== 8 &&
            !(
              (e.keyCode >= 48 && e.keyCode <= 90) ||
              (e.keyCode >= 96 && e.keyCode <= 105)
            )
          )
            return;

          let v = document.getElementById("autocomplete_agent_1").value;
          v = v ? v.replace(/'/g, "") : "";

          if (!v) return;

          store_analytics.dispatch({
            type: 1,
            agent: v,
            filters: true,
            agent_code: "",
          });
          let params = {
            agent: v,
            sales_region: cookies.sales_region ? cookies.sales_region : "",
          };
          let agent_suggestion = await Routes.get("/Agent", params, true);
          agent_suggestion = agent_suggestion ? agent_suggestion : [];

          store_analytics.dispatch({
            type: 1,
            filters: true,
            agent_suggestion: agent_suggestion,
            agent_code:
              v === "" || store_analytics.getState().agent !== v
                ? ""
                : undefined,
            agent: v,
          });
        }}
        onChange={async (e, v) => {
          if (!v) return;
          store_analytics.dispatch({
            type: 1,
            filters: true,
            agent: v.name + " - " + v.code,
            agent_code: v.code,
          });
        }}
        onBlur={async (e, v) => {
          //let {carrier} = store_analytics.getState().filters
          //let v = document.getElementById ("autocomplete_carrier").value.split(" - ");
          //v = v[0].split(" ")
          //store_analytics.dispatch({type:1, filters : true, carrier : v })
          //this.loadData()
        }}
        size="small"
        style={{ fontSize: "0.2em" }}
        getOptionLabel={function (option) {
          return option.name + " - " + option.code;
        }}
        renderOption={function (option) {
          let agent =
            option.name +
            " - " +
            option.branch +
            " - " +
            option.city +
            " - " +
            option.code;
          let agent_query = store_analytics.getState().filters.agent;
          agent = Util.AgentFormat(agent, agent_query);
          let agent_a = <Interweave content={agent} />;
          return agent_a;
        }}
      />
    );
  }
}
