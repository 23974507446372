import React from "react";
import watch from 'redux-watch'
import { Backdrop, CircularProgress, FormControlLabel, Checkbox, FormControl, FormLabel,RadioGroup, Radio} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Refresh from '@material-ui/icons/Refresh';
import Clear from '@material-ui/icons/Clear';
import People from '@material-ui/icons/People';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/DeleteForever';
import AddPerson from '@material-ui/icons/PersonAdd';
import ReactTooltip from "react-tooltip";
import Interweave from 'interweave'
import { Modal } from 'react-bootstrap';
import '../styles/Users.css';
import cookies from "../Cookies";
import Routes from '../Routes.js'
import { connect } from 'react-redux';
import Users_State from '../states/Users_State.js'
import util from "../util";
import * as Excel from "exceljs";
import {saveAs } from 'file-saver'
// Logger with default options
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger'
import conf from '../config.js'
 
const logger = createLogger({
  //predicate, // if specified this function will be called before each action is processed with this middleware.
  //collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  duration : false  , // print the duration of each action?
  timestamp : true, // print the timestamp with each action?
 
  //level : 'log': 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
  //colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
  //titleFormatter, // Format the title used when logging actions.
 
  //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
  //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
  //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.
 
  logger : console, // implementation of the `console` API.
  logErrors : true, // should the logger catch, log, and re-throw errors?
 
  diff : false, // (alpha) show diff between states?
  //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`

});

var config = conf.config ;
var store_users = createStore(Users_State.reducer_users, applyMiddleware(logger))
var store_filters = createStore(Users_State.reducer_filters, applyMiddleware(logger))

class Main extends React.Component {
constructor(props) {
    super(props);  
    this.state = {
      export_csv : "1",
      key : Date.now()
    }

  }

async componentDidMount() {

    document.title = "Quantum - Users"; 

    store_users.dispatch({type:1, page_loading_spinner : true})  

    await getConnected()
    
    let filters = store_filters.getState()
    let res = await Routes.get("/Users", filters, true)
    res = res ? res : []

    return store_users.dispatch({type : 1, users : res, page_loading_spinner : false  }) 
            
}
 
render() {

  if(!this.props.loggedIn || !cookies.rights || (cookies.rights && !cookies.rights.users_view)){
    return window.location.replace(config.front_host)
  }   

  return <div key={this.state.key}>

      {this.state.notification}

      <div class="container-fluid background">
        
        <PageLoadingSpinner />

        <FilterMenu authorized_stations = {this.props.authorized_stations} />

        <ModalConfirm/>

        <ExportModal authorized_stations = {this.props.authorized_stations}/>

        <ModalAddUser authorized_stations = {this.props.authorized_stations}/>

        <ModalUpdateUser authorized_stations = {this.props.authorized_stations}/>

        <ModalDeleteUser/>

        <UsersList authorized_stations = {this.props.authorized_stations} class="row" />
 
      </div> 
  
  </div>
}

}
 
export default connect(
  (state) => ({
    authorized_stations: state.authorized_stations, 
    loggedIn : state.loggedIn
  })
)(Main) 

class UsersList extends React.Component{
  
constructor(props){
    super(props);
    this.state = {
      key : Date.now()
    }
}

componentDidMount(){
  store_filters.subscribe(()=>{
  this.setState({key:Date.now()})})
  let w1 = watch(store_users.getState, 'users')
  store_users.subscribe(w1((newVal, oldVal, objectPath) => {
    this.setState({key : Date.now()})
  }))
}

render(){

  let html = []
  let users = store_users.getState().users;
  let filters = store_filters.getState();
  //let stations = this.props.authorized_stations ? this.props.authorized_stations : []

  if(!users) return
   
  let users_filtered = []
  
  users.forEach((u)=>{
    let role = ""
    if(u.role === util.USER_ROLES.ADMIN) role = "Administrator"
    else if(u.role === util.USER_ROLES.STATION_ADMIN) role = "Station Admin"
    else if(u.role === util.USER_ROLES.SALES_STD) role = "Sales STD"
    else if(u.role === util.USER_ROLES.SALES_LITE) role = "Sales Lite"
    else if(u.role === util.USER_ROLES.OPS_LITE) role = "Ops Lite"
    else if(u.role === util.USER_ROLES.OPS_STD) role = "Ops STD"
        
    if(filters.email.trim() !== "" && !u.email.includes(filters.email) ){
      return
    }

    if(filters.first_name.trim() !== "" && !u.first_name.toLowerCase().includes(filters.first_name.toLowerCase())){
      return
    }

    if(filters.last_name.trim() !== "" && !u.last_name.toLowerCase().includes(filters.last_name.toLowerCase())){
      return
    }

    if(filters.email_contact.trim() !== "" && !u.email_contact.toLowerCase().includes(filters.email_contact.toLowerCase())){
      return
    }

    if(filters.role.toLowerCase() !== "all" && filters.role.toLowerCase() !== u.role.toLowerCase()){
      return
    }

    if(filters.country.trim() !== "" && !u.country_code.toLowerCase().includes(filters.country.toLowerCase())){
      return
    }

    if(filters.region.toLowerCase() !== "all" && !u.region.toLowerCase().includes(filters.region.toLowerCase())){
      return
    }

    if(filters.entity.toLowerCase() !== "all" && u.entity && u.entity.toLowerCase() !== filters.entity.toLowerCase()){
      return
    }

    if(filters.discovery !== null && filters.discovery !== undefined){
      if(filters.discovery !== u.discovery) return
    }

    if(filters.online !== null && filters.online !== undefined){
     if(filters.online !== u.isloggedIn) return
    }


    if(filters.hq !== null ){
  
      if((filters.hq && u.region !== "WLD") || (!filters.hq && u.region === "WLD")){
        return
      }

    }

    users_filtered.push(u) 

    html.push(      
    <tr>
      <td style = {{width : "6em"}}>{users.indexOf(u) + 1}</td>
      <td >{u.entity_code}</td>
      <td >{u.station_name} <strong>{u.entity === "WLD" ? "HQ" : ""}</strong></td>
      <td >{u.country_code} </td>
      <td >{u.region}</td>
      <td >{u.first_name ? <Interweave content = {util.FormatText(u.first_name, filters.first_name)} /> : ""}</td>
      <td >{u.last_name ? <Interweave content = {util.FormatText(u.last_name, filters.last_name, false)} /> : ""}</td>
      <td >{u.email ? <Interweave content = {util.FormatText(u.email, filters.email)} /> : ""}</td>
      <td >{role}</td>
      <td >{u.carriers && u.carriers.length > 15 ? u.carriers.substring(0,15) + "..."  : u.carriers}</td>
      <td >{u.except_carriers ? u.except_carriers.length  <= 18 ? u.except_carriers  :  u.except_carriers.substring(0,15) + "..."  : "None"}</td>
      <td class="extra-large">{u.email_contact ? <Interweave content = {util.FormatText(u.email_contact, filters.email_contact)} /> : ""}</td>
      <td>
       {!u.last_login ? 'Never' : isConnected(u) ? <>Online <img src={require("../img/green-circle.png")} alt="connected" /></>  : dateToString(new Date(u.last_login))}
      </td>
      <td>{u.discovery ? "✔️" : "❌"}</td>
      <td>
        <button 
        disabled = {cookies && cookies.role && cookies.role === 'admin' ? false : true}
        class ='btn btn-primary' 
        style = {{paddingLeft : '0.5em', paddingRight : '0.5em', marginLeft : '1em'}}
          onClick = {(e)=> {
            u.id = u.email
            store_users.dispatch({type:1, user : u, updateModal : true })
          }}
          data-tip = "Update User"
          data-type = 'info'


        >
          <Edit fontSize="small"/>

        </button>

        <button 
        disabled = {cookies && cookies.role && cookies.role === 'admin' ? false : true}
        class ='btn btn-danger' 
        style = {{paddingLeft : '0.5em', paddingRight : '0.5em', marginLeft : '1em'}}
          onClick = {(e)=> {
            u.id = u.email
            store_users.dispatch({type:1, user : u, deleteModal : true })
          }}
          data-tip = "Delete User"
          data-type = 'info'

        >
           <Delete fontSize="small"/>
        </button>

      </td>
    
    </tr>)  

  })

  store_users.dispatch({type:1, users_filtered : users_filtered, countUsers : users_filtered.length })

  return (
    <div class="col users-list" style={{ fontSize: "smaller" }} key={this.state.key}>
      <table   class="table-users table-striped">
        <thead>
          <tr>
            <th scope = "col" style = {{width : "6em"}} >#</th>
            <th scope = "">Entity Code</th>
            <th scope = "">Entity</th>
            <th scope = "">Country</th>
            <th scope = "">Stations</th>
            <th scope="col" >First Name</th>
            <th scope="col" >Last Name</th>
            <th scope="col" >Account</th>
            <th scope="col" >Role</th>
            <th scope="col" >Allowed Carriers</th>
            <th scope="col" >Denied Carriers</th>
            <th scope="col" class="extra-large">Email Contact</th>
            <th scope="col">Last Login</th>
            <th scope="col">Discovery</th>
            <th scope="col" >Edit</th>
          </tr>
        </thead>
        <tbody class="us-list-body">
          {html}
        </tbody>
      </table>
  </div> 
  )
    
}

}

class FilterMenu extends React.Component{
  
  constructor(props){
    super(props)
    this.state = { 
       key : Date.now(),
       scrolled : false, 
       options : [ 
        {value : null , name : "All"  }, 
        {value : true , name : "Yes" }, 
        {value : false , name : "No" }
      ],
      
      online_options : [ 
        {value : null , name : "All"  }, 
        {value : 'yes' , name : "Yes" }, 
        {value : 'no' , name : "No" },
        {value : 'never' , name : "Never" }
      ] 
      }
  }

  componentDidMount(){
    let w1 = watch(store_filters.getState, 'reset')
    store_filters.subscribe(w1((newVal, oldVal, objectPath) => {
        console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({key : Date.now(), scrolled : false})
      }))

      let w2 = watch(store_users.getState, 'users')
      store_users.subscribe(w2((newVal, oldVal, objectPath) => {
        console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
        this.setState({key : Date.now(), scrolled : false})
      }))

  }

  async onClickReset(e){
    store_filters.dispatch({type : 1, reset : true})
    this.onClickRefresh()
  }

  async onClickRefresh(e){
    store_users.dispatch({type:1, page_loading_spinner : true})
    await getConnected()
    let filters = store_filters.getState()
    let res = await Routes.get("/Users", filters, true)
    store_users.dispatch({type : 1, users : res, page_loading_spinner : false  }) 
  }



  render(){
   
    const filters_state = store_filters.getState();
 
    let hq_curr = this.state.options.filter((h) =>{return h.value === filters_state.hq})[0]
    let discovery_curr = this.state.options.filter((d) =>{return d.value === filters_state.discovery})[0]
    let online_curr = this.state.online_options.filter((o) =>{return o.value === filters_state.online})[0]
    let hq_entity = {
      station_name : "QUITO HQ" , 
      country_code : "FR", 
      station : "WLD", 
      entity_code : "98", 
      client_id : "", 
      origin_zone : null,
      brand_code : "QUITO"
    }
    let entities = this.props.authorized_stations ? this.props.authorized_stations : []

    if(!entities.some((e) => e.station === "WLD") ) {
     entities.unshift(hq_entity)
    }

    if(cookies.region !== "WLD"){
      entities = entities.filter((e) => e.station !== "WLD")
    }
    
    let stations = entities

    return (

      <div key={this.state.key} class= { "filter-menu"}>
         
        <div class="row">

          <div class="col-2">

            <button 
              type="button" 
              class="btn btn-success" 
              data-tip = "Users List"
              data-type = 'info'

              >
              <People fontSize="small"/>
              <span>{"  "}{store_users.getState().countUsers}</span>
            </button>

            <button 
              type="button" 
              class="btn btn-danger" 
              data-tip = "Clear Search"
              data-type = 'info'
              onClick={async (e)=>{ this.onClickReset(e)}}>
                 <Clear fontSize="small"/>
            </button>

            <button 
              type="button" 
              class="btn btn-primary" 
              data-tip = "Refresh"
              data-type = 'info'
              onClick={async (e)=>{this.onClickRefresh(e)}}>
                 <Refresh fontSize="small"/>
            </button>

            <button type="button" 
                class="btn btn-success"  
                data-tip = "Download"
                data-type = 'info'
                onClick={ (e) => store_users.dispatch({type : 1, exportModal : true, export_type : "csv"})} > 
               <GetAppIcon fontSize="small"/>
            </button>
            {
             // <ReactTooltip place="top" effect="solid" delayHide={500} />
              }

            <ExportButton/>

              <div style = {{marginTop : '0.5em'}}>
                <img src={require("../img/green-circle.png")} alt="connected" /> <span>Connected : {store_users.getState().connected}/{store_users.getState().countUsers}</span> 
              </div>
  
          </div>

          <div class="col-10">

            <div class="row row-filter"> {/* FILTERS */}

            <div class="col">
              <label >Entity</label>
              <select class="form-control dropdown" id="inputGroupSelect01"  
                onChange={(e)=>{  
                 store_filters.dispatch({type : 1, entity : e.target.value})
                }}
              >

                <option value={filters_state.entity}>{filters_state.entity.toUpperCase()} </option>

                {filters_state.entity === "All" ?
                  null
                :
                  <option value="All"> ALL </option>
                }
                {
                  cookies && (cookies.role === 'admin' || cookies.entity === "WLD") ?
                  entities.filter((s)=> s.station !== filters_state.entity ).map((s)=>{
                    return (<option value={s.station}>{s.country_code} - {s.station_name} {"(" + s.entity_code + ")"} - {s.station} </option>)
                  })
                  : entities.filter((s)=> s.station === cookies.entity ).map((s)=>{
                    return (<option value={s.station}>{s.country_code} - {s.station_name} {"(" + s.entity_code + ")"} - {s.station} </option>)
                  })
                }
              </select>
            </div>

            <div class="col">
              <label >Stations</label>
              <select class="form-control dropdown" id="inputGroupSelect01"  
                onChange={(e)=>{  
                 store_filters.dispatch({type : 1, region : e.target.value})
                }}
              >

                <option value={filters_state.region}>{filters_state.region.toUpperCase()} </option>

                {filters_state.region === "All" ?
                  null
                :
                  <option value="All"> ALL </option>
                }
                {
                  stations.filter((s)=> s.station !== filters_state.region ).map((s)=>{
                    return (<option value={s.station}>{s.country_code} - {s.station_name} {s.entity_code ? "(" + s.entity_code + ")" : ""} - {s.station} </option>)
                  })
                }
              </select>
            </div>

            <div class="col">
              <label >Country</label>
              <input maxLength={2} type="text" class="form-control" defaultValue={filters_state.country} 
                onChange={(e)=>{ 
                  store_filters.dispatch({type : 1, country : e.target.value})
                }}
              />
            </div>

            <div class="col">
              <label >First Name</label>
              <input type="text" class="form-control" defaultValue={filters_state.first_name} 
                onChange={(e)=>{ 
                  store_filters.dispatch({type : 1, first_name : e.target.value})
                }}
              />
            </div>

            <div class="col">
              <label >Last Name</label>
              <input type="text" class="form-control" defaultValue={filters_state.last_name} 
                onChange={(e)=>{ 
                  store_filters.dispatch({type : 1, last_name : e.target.value})
                }}
              />
            </div>

            <div class="col">
              <label >Account</label>
              <input type="text" class="form-control" defaultValue={filters_state.email} 
                onChange={(e)=>{ store_filters.dispatch({type : 1, email : e.target.value})}
              }/>
            </div>

            <div class="col">
              <label >Email Contact</label>
              <input type="text" class="form-control" defaultValue={filters_state.email_contact} 
                onChange={(e)=>{ 
                  store_filters.dispatch({type : 1, email_contact : e.target.value})
                }}
              />
            </div>

            <div class="col">
              <label >Role</label>
              <select class="form-control dropdown" id="inputGroupSelect01"  
                onChange={(e)=>{ return store_filters.dispatch({type : 1, role : e.target.value})}}
              >
                <option value={filters_state.role.toLowerCase()}>{filters_state.role.toUpperCase()}</option>
                {"All" === filters_state.role ? null : <option value="All">ALL</option>}
                {util.USER_ROLES_ARRAY.map((u)=>{
                  if(u === filters_state.role)
                    return null
                  else 
                    return <option value={u}>{u.toUpperCase()}</option>
                  })
                }
              </select>

            </div>

            <div class="col">
              <label >HQ</label>
              <select class="form-control dropdown" id="inputHQ"  
                onChange={(e)=>{ return store_filters.dispatch({type : 1, hq :  e.target.value})}}
              >
                <option value={filters_state.hq}>{hq_curr.name.toUpperCase()}</option>
                {this.state.options.map((e)=>{
                  if(e.value!==filters_state.hq) 
                    return <option value={e.value}> {e.name.toUpperCase()} </option>
                  else return null
                })
                }
              </select>
            </div>

            <div class="col">
              <label >Discovery</label>
              <select class="form-control dropdown" id="inputHQ"  
                onChange={(e)=>{ return store_filters.dispatch({type : 1, discovery :  e.target.value})}}
              >
                <option value={filters_state.discovery}>{discovery_curr.name.toUpperCase()}</option>
                {this.state.options.map((e)=>{
                  if(e.value !== filters_state.discovery) 
                    return <option value={e.value}> {e.name.toUpperCase()} </option>
                  else return null
                })
                }
              </select>
            </div>

            <div class="col">
              <label >Online</label>
              <select class="form-control dropdown" id="inputHQ"  
                onChange={(e)=>{ store_filters.dispatch({type : 1, online :  e.target.value})}}
              >
                <option value={filters_state.online}>{online_curr.name.toUpperCase()}</option>
                {this.state.online_options.map((e)=>{
                  if(e.value !== filters_state.online) 
                    return <option value={e.value}> {e.name.toUpperCase()} </option>
                  else return null
                })
                }
              </select>
            </div>

            <div class="col" >
              <button 
                disabled = {cookies && cookies.role && cookies.role === 'admin' ? false : true}
                data-tip = "Add new user"
                data-type = 'info'
                type='button' class ="btn btn-success" style = {{marginTop : '2em'}}
                onClick = {(e) => {
                  store_users.dispatch({type:1,  addModal : true})
                }}
              >
                <AddPerson fontSize="small"/>
              {  
              <ReactTooltip place="top" effect="solid" delayHide={500} />
              }
              </button>
            </div>
            
          </div>  

        </div>

      </div>

     </div>

    )

  }

}

class PageLoadingSpinner extends React.Component{
  constructor(props){
    super(props);

    this.state = {  key : Date.now() }
    this.style = {
      position: "absolute",
      left: "50%",
      margin : "auto",
      "margin-top" : "20em",
      "z-index" : "10",
      color : "white"
  }

  }

  componentDidMount(){
    let w1 = watch(store_users.getState, 'page_loading_spinner')
    store_users.subscribe(w1((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))

  }

  render(){
    let show = store_users.getState().page_loading_spinner
    if(!show) return null
    
    else{

      return (
        <div 
          key={this.state} 
          style = {this.style} 
          class="" 
          role="status"
        >
            <Backdrop   open={true}  >
            <CircularProgress color="inherit" />
            </Backdrop>
        </div>

      )
    }
 
  }

}

class ModalAddUser extends React.Component{
  constructor(props){
    super(props);
    this.modal_style = {
      padding : "1em"
    }
  }

  componentDidMount(){
    
    let w = watch(store_users.getState, 'addModal')
    store_users.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))
          
  }

  onClickSave(){
    if(!checkFields()){
      store_users.dispatch({type : 1, confirmModal : true})
    }
    
    this.setState({key : Date.now()}) 
    
  }

  onClickClose(){
    store_users.dispatch({type:1,  addModal : false})   
    store_users.dispatch({
      type:1, 
      error : false, 
      errors : {
        first_name : "", 
        last_name : "", 
        region : "",
        entity : "", 
        email : "", 
        email_contact : "", 
        role : "", 
        carriers : "",
        except_carriers : ""
      }
    })

    store_users.dispatch({
      type:1, 
      user : {
        id : null, 
        first_name : "", 
        last_name : "",
        entity : "", 
        region : "", 
        email : "", 
        email_contact : "", 
        role : "", 
        carriers : "ALL",
        except_carriers : "",
        discovery : false
      }
    })    
  }
  render(){

   let show = store_users.getState().addModal;
   let user = store_users.getState().user
   let errors = store_users.getState().errors
   let hq_entity = {
    station_name : "QUITO HQ" , 
    country_code : "FR", 
    station : "WLD", 
    entity_code : "98", 
    client_id : "", 
    origin_zone : null,
    brand_code : "QUITO"
  }
  let entities = this.props.authorized_stations ? this.props.authorized_stations : []
  if(!entities.some((e) => e.station === "WLD")) entities.unshift(hq_entity)
   


  return(
      <Modal show={show}
      dialogClassName="modal-m"
      onHide={()=>{store_users.dispatch({type:1,  addModal : true})} }>
      <Modal.Header className="user_modal_header">
        <Modal.Title >
          <div>
              Add new User 
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 



        <div class = 'row'  style = {this.modal_style}>
            
            <div class="col-6">
              <label >Entity</label>
              <select class="form-control dropdown" id="inputGroupSelect01"  
                onChange={(e)=>{  
                 store_users.dispatch({type : 1, addUser : true, entity : e.target.value})
                }}
              >
                <option value={user.entity}>{user.entity.toUpperCase()} </option>
                {
                 entities.filter((s)=> s.station !== user.entity).map((s)=>{
                 return (<option value={s.station}>{s.country_code} - {s.station_name} {s.entity_code ? "(" + s.entity_code + ")" : ""} - {s.station} </option>)
                 })
                }
              </select>
               <div class ='error'>{errors.entity ? errors.entity : ""}</div>
            </div>

            <div class="col-6">
              <label >Stations</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.region ? "is-invalid" : "" )}  
                maxLength = {50}
                defaultValue={user.region} 
                onChange={(e)=>{store_users.dispatch({type : 1, addUser : true, region : e.target.value.toUpperCase()})}}
              />
               <div class ='error'>{errors.region ? errors.region : ""}</div>
            </div>
        </div>

        <div class = 'row'  style = {this.modal_style}>
            
            <div class="col-6">
              <label >First Name</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.first_name ? "is-invalid" : "" )} 
                maxLength = {50}
                defaultValue={user.first_name} 
                onChange={(e)=>{ store_users.dispatch({type : 1, addUser : true,  first_name : util.firstCharToUppercase(e.target.value)})}}
              />
              <div class ='error'>{errors.first_name  ? errors.first_name : ""}</div>
            </div>

            <div class="col-6">
              <label >Last Name</label>
              <input 
                type="text" 
                class= {"form-control " + (errors.last_name ? "is-invalid" : "" )} 
                defaultValue={user.last_name} 
                maxLength = {50}
                onChange={(e)=>{ 
                  store_users.dispatch({type : 1, addUser : true,  last_name : e.target.value})
                }}
              />
              <div class ='error'>{errors.last_name ? errors.last_name : ""}</div>
            </div>
        </div>

        <div class = 'row'  style = {this.modal_style}>
          
            <div class="col-6">
              <label >Account</label>
              <input 
                type="text"  
                class= {"form-control " + (errors.email ? "is-invalid" : "" )} 
                maxLength = {50}
                defaultValue={user.email.split('@')[0]} 
                onChange={(e)=>{ store_users.dispatch({type : 1, addUser : true, email : e.target.value.trim()})}}
              />
               <div class ='error'>{errors.email ? errors.email : ""}</div>
            </div>

            <div class="col-6">
              <label >Email Contact</label>
              <input 
                type="text"
                class= {"form-control " + (errors.email_contact ? "is-invalid" : "" )} 
                maxLength = {50}
                defaultValue={user.email_contact} 
                onChange={(e)=>{store_users.dispatch({type : 1, addUser : true, email_contact : e.target.value})}}
              />
            </div>

        </div>

        <div class = 'row'  style = {this.modal_style}>
            
       
            <div class="col-6">
              <label >Allowed Carriers</label>
              <input 
                type="text" class= {"form-control " + (errors.carriers  ? "is-invalid" : "" )}  
                maxLength = {200}
                defaultValue={user.carriers} 
                onChange={(e)=>{ store_users.dispatch({type : 1, addUser : true, carriers : e.target.value.toUpperCase()})}}
              />
              <div class ='error'>{errors.carriers ? errors.carriers : ""}</div>
            </div>

            <div class="col-6">
              <label >Denied Carriers</label>
              <input 
                type="text" class= {"form-control " + (errors.except_carriers  ? "is-invalid" : "" )} 
                maxLength = {100} 
                defaultValue={user.except_carriers} 
                onChange={(e)=>{ store_users.dispatch({type : 1, addUser : true, except_carriers : e.target.value.toUpperCase()})}}
              />
              <div class ='error'>{errors.except_carriers ? errors.except_carriers : ""}</div>
            </div>

      
        </div>

        <div class = 'row'  style = {this.modal_style}>
            

            <div class="col-10">
              <label >Role</label>
              <select class={"form-control " + (errors.role  ? "is-invalid" : "" ) } 
                onChange={(e)=>{ 
                  store_users.dispatch({type : 1, addUser : true, role : e.target.value})
                }}
              >
                <option value={user.role.toLowerCase()}>{user.role.toUpperCase()}</option>
                {util.USER_ROLES_ARRAY.map((u)=>{
                  if(u === user.role)
                    return null
                  else 
                    return <option value={u}>{u.toUpperCase()}</option>
                  })
                }
              </select>
              <div class ='error'>{errors.role ? errors.role : ""}</div>
            </div>

            <div class="col-2" style={{textAlign : "center" }}>
              <FormControlLabel
                value="bottom"
                control = {        
                  <Checkbox
                    defaultChecked = {user.discovery}
                    color="primary"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onChange={ (e)=>{store_users.dispatch({type : 1,addUser : true, discovery : e.target.checked })}}
                  />
                }
                label={"Discovery"}
                labelPlacement="top"
              />
            </div>
        </div>


        <div class="row" style = {{textAlign : 'center'}}>

          <div class="col-4"></div>

          <div class="col-4">
            <button type="button" class="col-2 btn btn-success" style = {{marginRight : '1em'}} 
              onClick={async (e)=>{this.onClickSave(e)}}>
              Save
            </button>

            <button type="button" class="col-2 btn btn-danger" style = {{marginLeft : '1em'}} 
              onClick={(e)=>{this.onClickClose(e)}}>
              Close
            </button>

          </div>
          
          <div class="col-4"></div> 

        </div>
           
      </Modal.Body>

      <Modal.Footer>

      </Modal.Footer>
    </Modal>      
    )

  }

}

class ModalUpdateUser extends React.Component{
  constructor(props){
    super(props);
    this.modal_style = {
      padding : "1em"
    }

  }

  componentDidMount(){
    
    let w = watch(store_users.getState, 'updateModal')
    store_users.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))
       
  }

  onClickUpdate(e){
    let updated = store_users.getState().userUpdated

    if(updated){

        if(!checkFields()){
          store_users.dispatch({type : 1, confirmModal : true})
        }
        this.setState({key : Date.now()})
         

    }else{

      store_users.dispatch({type:1,  updateModal : false})  

      store_users.dispatch({
        type:1, 
        error : false, 
        errors : {
          first_name : "", 
          last_name : "", 
          region : "", 
          entity : "", 
          email : "", 
          email_contact : "", 
          role : "", 
          carriers : "",
          except_carriers : ""
        }})  

      store_users.dispatch({
        type:1, 
        user : {
          id : null, 
          first_name : "", 
          last_name : "", 
          region : "", 
          entity : "", 
          email : "", 
          email_contact : "", 
          role : "", 
          carriers : "ALL", 
          except_carriers : "",
          discovery : false
        }}) 

    }
  }

  onClickClose(e){
    
    store_users.dispatch({type:1,  updateModal : false})  

    store_users.dispatch({
      type:1, 
      error : false, 
      errors : {
        first_name : "", 
        last_name : "", 
        region : "", 
        entity : "", 
        email : "", 
        email_contact : "", 
        role : "", 
        carriers : "",
        except_carriers : ""
      }})  

    store_users.dispatch({
      type:1, 
      user : {
        id : null, 
        first_name : "", 
        last_name : "", 
        region : "", 
        entity : "", 
        email : "", 
        email_contact : "", 
        role : "", 
        carriers : "ALL", 
        except_carriers : "",
        discovery : false
      }})  

  }
  
  render(){

   let show = store_users.getState().updateModal;
   let user = store_users.getState().user
   let errors = store_users.getState().errors
   let hq_entity = {
    station_name : "QUITO HQ" , 
    country_code : "FR", 
    station : "WLD", 
    entity_code : "98", 
    client_id : "", 
    origin_zone : null,
    brand_code : "QUITO"
  }
  let entities = this.props.authorized_stations ? this.props.authorized_stations : []
  if(!entities.some((e) => e.station === "WLD")) entities.unshift(hq_entity)
 
    return(
      <Modal show={show}
      dialogClassName="modal-m"
   
      onHide={()=>{store_users.dispatch({type:1,  updateModal : true})} }>
      <Modal.Header className="user_modal_header">
        <Modal.Title>
            <div>
              Update User
            </div>
       
          </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
      
        <div class = 'row'   style = {this.modal_style}>

            <div class="col-6">
              <label >Entity</label>
              <select class="form-control dropdown" id="inputGroupSelect01"  
                onChange={(e)=>{  
                 store_users.dispatch({type : 1, updateUser : true, entity : e.target.value, userUpdated: true})
                }}
              >
                <option value={user.entity}>{user.entity ? user.entity.toUpperCase() : null} </option>
                {
                 entities.filter((s)=> s.station !== user.entity ).map((s)=>{
                 return (<option value={s.station}>{s.country_code} - {s.station_name} {s.entity_code ? "(" + s.entity_code + ")" : ""} - {s.station} </option>)
                 })
                }
              </select>
              <div class ='error'>{errors.entity ? errors.entity : ""}</div>
            </div>

            <div class="col-6">
              <label >Stations</label>
              <input type="text" 
                class= {"form-control " + (errors.region ? "is-invalid" : "" )}  
                defaultValue={user.region} 
                maxLength = {100}
                onChange={(e)=>{ 
                  store_users.dispatch({type : 1, updateUser : true, region : e.target.value.toUpperCase(), userUpdated: true})
                }}
              />
              <div class ='error'>{errors.region ? errors.region : ""}</div>
            </div>
        </div>

        <div class = 'row'    style = {this.modal_style}>

            <div class="col-6">
              <label >First Name</label>
              <input 
                type="text" class= {"form-control " + (errors.first_name  ? "is-invalid" : "" )} 
                defaultValue={user.first_name} 
                maxLength = {50}
                onChange={(e)=>{ 
                store_users.dispatch({type : 1, updateUser : true,  first_name : util.firstCharToUppercase(e.target.value), userUpdated: true})
              }}/>
              <div class ='error'>{errors.first_name  ? errors.first_name : ""}</div>
            </div>

            <div class="col-6">
              <label >Last Name</label>
              <input type="text" class= {"form-control " + (errors.last_name  ? "is-invalid" : "" )}
                defaultValue={user.last_name} 
                maxLength = {50}
                onChange={(e)=>{ 
                store_users.dispatch({type : 1, updateUser : true,  last_name : e.target.value, userUpdated: true})
              }}/>
              <div class ='error'>{errors.last_name  ? errors.last_name : ""}</div>
            </div>

        </div>

        <div class = 'row'    style = {this.modal_style}>

            <div class="col-6">
              <label >Account</label>
              <input 
                type="text" 
                maxLength = {50}
                class= {"form-control " + (errors.email ? "is-invalid" : "" )} 
                defaultValue={user.email} 
                onChange={(e)=>{ 
                  let email = e.target.value.trim()
                  store_users.dispatch({type : 1, updateUser : true, email : email, userUpdated: true})
                }}/>

              <div class ='error'>{errors.email ? errors.email : ""}</div>

            </div>

            <div class="col-6">
              <label >Email Contact</label>
              <input 
                type="text"
                class= {"form-control " + (errors.email_contact !== undefined && errors.email_contact !== "" ? "is-invalid" : "" )} 
                maxLength = {50}
                defaultValue={user.email_contact} 
                onChange={(e)=>{ 
                  store_users.dispatch({type : 1, updateUser : true, email_contact : e.target.value, userUpdated: true})
                }}
              />

              <div class ='error'>{errors.email_contact ? errors.email_contact : ""}</div>

            </div>

        </div>

        <div class = 'row'    style = {this.modal_style}>

            <div class="col-6">
            <label >Allowed Carriers</label>
              <input type="text" class= {"form-control " + (errors.carriers  ? "is-invalid" : "" )}  
                defaultValue={user.carriers} 
                maxLength = {200}
                onChange={(e)=>{ 
                store_users.dispatch({type : 1, updateUser  : true, carriers : e.target.value.toUpperCase(), userUpdated : true})
              }}/>
               <div class ='error'>{errors.carriers ? errors.carriers : ""}</div>
            </div>

            <div class="col-6">
              <label >Denied Carriers</label>
              <input type="text" class= {"form-control " + (errors.except_carriers  ? "is-invalid" : "" )}  
                defaultValue={user.except_carriers} 
                maxLength = {100}
                onChange={(e)=>{ 
                store_users.dispatch({type : 1, updateUser  : true, except_carriers : e.target.value.toUpperCase(), userUpdated : true})
              }}/>
               <div class ='error'>{errors.except_carriers ? errors.except_carriers : ""}</div>
            </div>

        </div>

        <div class = 'row'    style = {this.modal_style}>

            <div class="col-10">

              <label >Role</label>
              <select class={"form-control " + (errors.role  ? "is-invalid" : "" ) } 
                onChange={(e)=>{ 
                  store_users.dispatch({type : 1, updateUser : true, role : e.target.value, userUpdated: true})
                }}>

                <option value={user.role.toLowerCase()}>{user.role.toUpperCase()}</option>


              {
                util.USER_ROLES_ARRAY.map((u)=>{
                if(u === user.role)
                    return null
                  else 
                    return <option value={u}>{u.toUpperCase()}</option>
              })
              }

              </select>

              <div class ='error'>{errors.role ? errors.role : ""}</div>

            </div>

            <div class="col-2" style={{textAlign : "center" }}>
              <FormControlLabel
                value="bottom"
                control = {        
                  <Checkbox
                    defaultChecked = {user.discovery}
                    color="primary"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onChange={ (e)=>{store_users.dispatch({type : 1, updateUser  : true, discovery : e.target.checked, userUpdated : true })}}
                  />
                }
                label={"Discovery"}
                labelPlacement="top"
              />
            </div>

        </div>

        <div class = 'row'    style = {this.modal_style}>

          <div class="col-3">
            <label >Date Creation</label>
            <input 
              type="text" class= {"form-control " + (errors.carriers  ? "is-invalid" : "" )} 
              disabled 
              defaultValue={user.date_created ? dateToString(new Date(user.date_created)) : ""} 
            />
          </div>

          
          <div class="col-3">
            <label >Last Update</label>
            <input 
              type="text" 
              class= {"form-control " + (errors.carriers  ? "is-invalid" : "" )}  
              disabled
              defaultValue={user.date_updated ? dateToString(new Date(user.date_updated)) : ""} 
            />
          </div>

          <div class="col-3">
            <label >First Login</label>
            <input 
              type="text" 
              class= {"form-control " + (errors.carriers  ? "is-invalid" : "" )}  
              disabled
              defaultValue={user.first_login ? dateToString(new Date(user.first_login)) : "Never"} 
            />
          </div>

          <div class="col-3">
            <label >Last Login</label>
            <input 
              type="text" 
              class= {"form-control " + (errors.carriers  ? "is-invalid" : "" )}  
              disabled
              defaultValue={user.last_login ? dateToString(new Date(user.last_login)) : "Never"} 
            />
          </div>


   

        </div>
        
        <div class="row" style = {{textAlign : 'center'}}>

          <div class="col-4"></div>

            <div class="col-4">
       
              <button type="button" class="col-2 btn btn-success" style = {{marginRight : '1em'}}
                onClick={async (e)=>{ this.onClickUpdate(e)}}>Update
              </button>

              <button type="button" class="col-2 btn btn-danger" style = {{marginLeft : '1em'}} 
                onClick={(e)=>{this.onClickClose(e)}}>
                  Close
              </button>

            </div>

            <div class="col-4"></div> 

        </div>
        
      </Modal.Body>

      <Modal.Footer>

      </Modal.Footer>
    </Modal>      
    )

  }

}

class ModalDeleteUser extends React.Component{
  constructor(props){
    super(props);
    this.modal_style = {
      padding : "1em"
    }
  }
  
  componentDidMount(){
    
    let w = watch(store_users.getState, 'deleteModal')
    store_users.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))

  }

  async onClickYes(e){
     
    let params = store_users.getState().user
    let operator = ''
    if(cookies){
      operator = cookies.first_name  ? cookies.first_name.toUpperCase() : ''
      operator = operator + ' ' + (cookies.last_name  ? cookies.last_name.toUpperCase() : '')
    }

    params.operator = operator

    if(params.id){
      store_users.dispatch({type:1,  page_loading_spinner : true})
      params.delete = true
      await Routes.post('/DeleteUser',params,true)
      await getConnected()
      let filters = store_filters.getState()
      let res = await Routes.get('/Users', filters, true)
      store_users.dispatch({type : 1, users : res,  page_loading_spinner : false  }) 
      store_users.dispatch({type:1,  deleteModal : false})  
      store_users.dispatch({
        type:1, 
        user : {
          id : null, 
          first_name : "", 
          last_name : "", 
          region : "", 
          entity : "",
          email : "", 
          email_contact : "", 
          role : "",
          discovery : false
        }
      }) 
      this.setState({key : Date.now()})
      
    }
  }

  onClickNo(e){
    store_users.dispatch({type:1,  deleteModal : false})  
    store_users.dispatch({
      type:1, 
      user : {
        id : null, 
        first_name : "", 
        last_name : "", 
        region : "", 
        entity : "",
        email : "", 
        email_contact : "", 
        role : "",
        discovery : false
      }
    })  
  }

  render(){

   let show = store_users.getState().deleteModal;
   let user = store_users.getState().user
    
  return(
    <Modal 
      show={show}
      dialogClassName="modal-m"
      onHide={()=>{store_users.dispatch({type:1,  deleteModal : true})} }
    >
      <Modal.Header className="user_modal_header">
        <Modal.Title>
          <div>
            Do you want to delete this user ?
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
      
        <div class = 'row'  style = {this.modal_style}>

            <div class="col-6">
              <label >Entity</label>
              <input type="text" class= {"form-control"}  defaultValue={user.entity} disabled = {true}/>
            </div>

            <div class="col-6">
              <label >Stations</label>
              <input type="text" class= {"form-control"}  defaultValue={user.region} disabled = {true}/>
            </div>

        </div>

        <div class = 'row'  style = {this.modal_style}>


            <div class="col-6">
              <label >First Name</label>
              <input type="text" class= {"form-control"} defaultValue={user.first_name} disabled ={true} />
            </div>

            <div class="col-6">
              <label >Last Name</label>
              <input type="text" class= {"form-control"} defaultValue={user.last_name} disabled = {true}/>
            </div>


        </div>

        <div class = 'row'  style = {this.modal_style}>

            <div class="col-6">
              <label >Account</label>
              <input type="text" class= {"form-control"} defaultValue={user.email.split('@')[0]} disabled = {true}/>
            </div>

            <div class="col-6">
              <label >Email Contact</label>
              <input type="text"class= {"form-control"} defaultValue={user.email_contact} disabled = {true}/>
            </div>

        </div>

        <div class = 'row'  style = {this.modal_style}>
            <div class="col-6">
              <label >Allowed Carriers</label>
              <input type="text" class= {"form-control"}  defaultValue={user.carriers} disabled = {true}/>
            </div>

            <div class="col-6">
              <label >Denied Carriers</label>
              <input type="text" class= {"form-control"}  defaultValue={user.carriers} disabled = {true}/>
            </div>
        </div>

        <div class = 'row'  style = {this.modal_style}>

            <div class="col-10">
              <label >Role</label>
              <input type="text"class= {"form-control"} defaultValue={user.role.toUpperCase()} disabled = {true}/>
            </div>


            <div class="col-2" style={{textAlign : "center" }}>
              <FormControlLabel
                value="bottom"
                control = {        
                  <Checkbox
                    defaultChecked = {user.discovery}
                    disabled = {true}
                    color="primary"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                  />
                }
                label={"Discovery"}
                labelPlacement="top"
              />
            </div>

        </div>
        
        <div class = 'row'    style = {this.modal_style}>

          <div class="col-3">
            <label >Date Creation</label>
            <input 
              type="text" class= "form-control"
              disabled 
              defaultValue={user.date_created ? dateToString(new Date(user.date_created)) : ""} 
            />
          </div>

          
          <div class="col-3">
            <label >Last Update</label>
            <input 
              type="text" 
              class= "form-control" 
              disabled
              defaultValue={user.date_updated ? dateToString(new Date(user.date_updated)) : ""} 
            />
          </div>

          <div class="col-3">
            <label >First Login</label>
            <input 
              type="text" 
              class= "form-control"
              disabled
              defaultValue={user.first_login ? dateToString(new Date(user.first_login)) : "Never"} 
            />
          </div>

          <div class="col-3">
            <label >Last Login</label>
            <input 
              type="text" 
              class= "form-control"
              disabled
              defaultValue={user.last_login ? dateToString(new Date(user.last_login)) : "Never"} 
            />
          </div>

        </div>
        
        <div class="row" style = {{textAlign : 'center'}}>

          <div class="col-4"></div>
            
          <div class="col-4">
       
            <button type="button" class="col-2 btn btn-success" style = {{marginRight : '1em'}}
              onClick={async (e)=>{this.onClickYes(e)}}>
                Yes
            </button>

            <button type="button" class="col-2 btn btn-danger" style = {{marginLeft : '1em'}} 
              onClick={(e)=>{this.onClickNo(e)}}>
                  No
            </button>

          </div>

          <div class="col-4"></div> 

        </div>
        
      </Modal.Body>

      <Modal.Footer>

      </Modal.Footer>
    </Modal>      
    )

  }

}

class ModalConfirm extends React.Component{
 

  componentDidMount(){
    let w = watch(store_users.getState, 'confirmModal')
    store_users.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))
    
  }
  
  async onClickYes(e){
    store_users.dispatch({type : 1, page_loading_spinner : true})
    let operator = ""
    
    if(cookies){
       operator = cookies.first_name  ? cookies.first_name.toUpperCase() : ""
       operator = operator + ' ' + (cookies.last_name  ? cookies.last_name.toUpperCase() : "")
    }

    let params = store_users.getState().user
    params.operator = operator


    if(store_users.getState().addModal){
      params.add = true
      await Routes.post('/Users', params, true)
    }else if(store_users.getState().updateModal){
      let params = store_users.getState().user
      params.last_login = undefined
      params.first_login = undefined
      params.date_updated = undefined
      params.notification = true
      params.update = true
      await Routes.post('/Users', params, true)
    }

    await getConnected()

    let filters = store_filters.getState()
    let res = await Routes.get("/Users",filters, true)

    store_users.dispatch({type : 1, users : res }) 
    store_users.dispatch({type : 1, page_loading_spinner : false})
    store_users.dispatch({type:1, confirmModal : false})
    store_users.dispatch({
      type:1, 
      user : {
        id : null, 
        first_name : "", 
        last_name : "",
        entity : "", 
        region : "", 
        email : "", 
        email_contact : "", 
        role : "", 
        carriers : "ALL",
        discovery : false
      }
    })
    store_users.dispatch({type:1, addModal : false})
    store_users.dispatch({type:1, updateModal : false})
    store_users.dispatch({type:1, userUpdated : false})

  }

  onClickNo(e){
    e.preventDefault();
    store_users.dispatch({type:1, confirmModal : false})
    store_users.dispatch({type:1, addModal : false})               
    store_users.dispatch({type:1, updateModal : false})  
    store_users.dispatch({type:1, userUpdated : false})
  }

  render(){

    let show = store_users.getState().confirmModal;

    return(
      <Modal show={show} onHide={()=>{}}>
      <Modal.Header className="user_modal_header">
        <Modal.Title style = {{textAlign : 'center', width : "100%"}}>
          <div >
            {store_users.getState().addModal ? "Do you want to save?" : "Do you want to save changes?"}
          </div>      
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
           
        <div class="row">
            <div class="col-2"></div>
      
            <button type="button" class="col-2 btn btn-success" 
              onClick={async (e)=>{this.onClickYes(e)}}>
              Yes
            </button>
              
            <div class="col-1"></div>
              
            <button type="button" class="col-2 btn btn-danger" 
              onClick={(e)=>{this.onClickNo(e)}}>
              No
            </button>

            <div class="col-1"></div>
                
            <button type="button" class="col-2 btn btn-secondary" 
              onClick={(e)=>{store_users.dispatch({type:1, confirmModal : false})}}>
              Cancel
            </button>

        </div>
        
      </Modal.Body>

      <Modal.Footer>

      </Modal.Footer>
    </Modal>      
    )

  }

}

class ExportModal extends React.Component{

  componentDidMount(){
    let w = watch(store_users.getState, 'exportModal')
    store_users.subscribe(w((newVal, oldVal, objectPath) => {
      //console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      if(oldVal!==newVal)
        this.setState({key : Date.now()})
      }))
    
  }
  
  async download(e){
    let {export_type} = store_users.getState()
    
    let stations = this.props.authorized_stations
    
    if(export_type === "csv"){
      exportUserstoCSV(stations);
      store_users.dispatch({type:1, export : true})
    }else if(export_type === "excel"){
      exportUserstoExcel(stations)
    }else if(export_type === "json")
      exportUserstoJSON(stations)
    else
       return

    let logs = {
      type : "EXPORT_USERS",
      to : export_type
    }

    await Routes.get("/Logger",logs, true) 

    store_users.dispatch({type : 1 , exportModal : false})
  }



  render(){

    let show = store_users.getState().exportModal;

    return(
      <Modal show={show} onHide={()=>{}}>
      <Modal.Header className="user_modal_header">
        <Modal.Title style = {{textAlign : 'center', width : "100%"}}>
          Choose your Output
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 

        <div class = "row">
          
            <div class = "col-1"></div>


            <div class = "col-10">
            <FormControl>
            <FormLabel id="row-radio-buttons-group-label">Export to :  </FormLabel>
            <RadioGroup
              row
              aria-labelledby="row-radio-buttons-group-label"
              defaultValue="csv"
              name="row-radio-buttons-group"
              onClick={(e) => {store_users.dispatch({type : 1, export_type : e.target.value})}}
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="excel" control={<Radio />} label="Excel" />
              <FormControlLabel  value="json" control={<Radio />} label="JSON" />
            </RadioGroup>
          </FormControl>

            </div>

            <div class = "col-1"></div>

        </div>

        <br/>
           
        <div class="row">

            <div class="col-3"></div>
      
            <button type="button" class="col-2 btn btn-success" 
              onClick={async (e)=>{this.download(e)}}>
              Download
            </button>
              
            <div class="col-2"></div>
              
                
            <button type="button" class="col-2 btn btn-danger" 
              onClick={(e)=>{store_users.dispatch({type:1, exportModal : false})}}>
              Close
            </button>

            <div class="col-3"></div>


        </div>
        
      </Modal.Body>

      <Modal.Footer>

      </Modal.Footer>
    </Modal>      
    )

  }

}

class ExportButton extends React.Component{

componentDidMount(){
  let w1 = watch(store_users.getState, 'export')
  store_users.subscribe(w1((newVal, oldVal, objectPath) => {
      console.log('%s changed from %s to %s', objectPath, oldVal, newVal)
      this.setState({key : Date.now()}, ()=>{
        document.getElementById("link_export").click()
      });
    }))

}

render(){

  let filename = "Quantum_Users_" + util.convertDate(new Date(), false, ".") + ".csv"
    return (
      <a 
        id="link_export" 
        download = {filename}
        href={"data:text/csv;charset=UTF-8," + store_users.getState().users_csv }
        hidden
      >
     </a>
    )
  }

}


function dateToString(datetime){
  return  ("0" + datetime.getDate()).slice(-2)  +"/"+ ("0" + (datetime.getMonth()+1)).slice(-2)+"/" + datetime.getFullYear() +" "+ ("0" + datetime.getHours()).slice(-2)+":" + ("0" + datetime.getMinutes()).slice(-2)
}

function checkFields(){
 
  let user = store_users.getState().user

  store_users.dispatch({
    type:1, 
    error : false, 
    errors : {
      first_name : "", 
      last_name : "", 
      region : "", 
      entity : "",
      email : "", 
      email_contact : "", 
      role : "", 
      carriers : ""
    }
  })

  if(!user.entity.trim()){
    store_users.dispatch({type:1, error : true, entity : "Missing."})
  }else if(user.entity && (user.entity.length !== 3)){
      store_users.dispatch({type:1, error : true, entity : "Invalid entity."})
  }
  
  if(!user.region.trim()){
    store_users.dispatch({type:1, error : true, region : "Missing."})
  }else{
    let regions = user.region.split(',')
    regions.forEach((r) => {
      if(r.length !== 3) 
      store_users.dispatch({type:1, error : true, region : "Invalid region."})
    })
  }

  if(!user.first_name.trim()){
    store_users.dispatch({type:1, error : true, first_name : "Missing"})
  }

  if(!user.last_name.trim()){
    store_users.dispatch({type:1, error : true, last_name : "Missing"})
  }
  
  if(!user.email.trim()){
    store_users.dispatch({type:1, error : true, email : "Missing"})
  }

  if(!user.role.trim()){
    store_users.dispatch({type:1, error : true, role : "Missing"})
  }

  // if(user.email_contact){
  //   let email = user.email.trim()  
  //   let valid = util.validateEmail(email)
  //   // if(!valid)   
  //   //   store_users.dispatch({type:1, error : true, email_contact : "Invalid email"})
    
  // }

  if(!user.carriers.trim()){
    store_users.dispatch({type:1, error : true, carriers : "Missing"})
  }

  return store_users.getState().error
}

function exportUserstoCSV(stations = null) {
  
   var csv = "EntityCode;Entity;Stations;FirstName;LastName;Account;Role;Email Contact;Discovery;AllowedCarriers;DeniedCarriers;FirstLogin;LastLogin;DateCreated;DateUpdated";
   csv = csv +  "\n"
   //let stations_ = stations ? stations : []
   store_users.getState().users_filtered.forEach((u)=>{
     csv = csv + 
      u.entity_code + ";" + 
     (u.station_name + " " + u.country_code) + ";" + 
     u.region  + ";" + 
     u.first_name  + ";" + 
     u.last_name + ";" + 
     u.email + ";" + 
     util.firstCharToUppercase(u.role) + ";" + 
     u.email_contact + ";" + 
    (u.discovery ? "Yes" : "No" ) + ";" +
    ( u.carriers ? u.carriers : "None") + ";" +
    (u.except_carriers ? u.except_carriers : "None" ) + ";" +
    (!u.first_login ? "Never" : dateToString(new Date(u.first_login))) +";" +  
    (!u.last_login ? "Never" : dateToString(new Date(u.last_login))) + ";" + 
    dateToString(new Date(u.date_created)) + ";" +   
    dateToString(new Date(u.date_updated))
    csv = csv +  "\n"
     
   });

   store_users.dispatch({type:1, users_csv : csv })
 
}

function  exportUserstoExcel(stations = null){

  //let stations_ = stations ? stations : []
  let {users_filtered} = store_users.getState()
  users_filtered = users_filtered ? users_filtered : []

  let columns = [
    "Entity Code",
    "Entity",
    "Stations",
    "First Name",
    "Last Name",
    "Account",
    "Role",
    "Email Contact",
    "Discovery",
    "Allowed Carriers",
    "Denied Carriers",
    "First Login",
    "Last Login",
    "Date Created",
    "Date Updated"
  ]    

  let data = []

  users_filtered.forEach((u)=>{

    data.push([
      (u.entity_code),
      (u.station_name + " " + u.country_code),
      u.region,
      u.first_name,
      u.last_name,
      u.email,
      util.firstCharToUppercase(u.role),
      u.email_contact,
      (u.discovery ? "Yes" : "No" ),
      ( u.carriers ? u.carriers : "None"),
      (u.except_carriers ? u.except_carriers : "None" ) ,
      (!u.first_login ? "Never" : dateToString(new Date(u.first_login))) , 
      (!u.last_login ? "Never" : dateToString(new Date(u.last_login))) ,
      dateToString(new Date(u.date_created)), 
      dateToString(new Date(u.date_updated))

    ])
   
  });

  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('USERS');
  worksheet.getColumn("B").width = 20
  worksheet.getColumn("C").width = 20
  worksheet.getColumn("D").width = 20
  worksheet.getColumn("E").width = 20
  worksheet.getColumn("F").width = 20
  worksheet.getColumn("G").width = 20
  worksheet.getColumn("H").width = 30
  worksheet.getColumn("I").width = 20
  worksheet.getColumn("J").width = 20
  worksheet.getColumn("K").width = 20
  worksheet.getColumn("L").width = 20
  worksheet.getColumn("M").width = 20
  worksheet.getColumn("N").width = 20
  worksheet.getColumn("O").width = 20

  worksheet.addTable({
    name: 'UsersTable',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      theme: 'TableStyleMedium2',
      showRowStripes: false,
    },
    columns: columns.map((c) => {return {name : c, filterButton  : true } }) ,
    rows:data,
  });

//FORMATTAGE
  worksheet.getRow(1).eachCell((cell) => {
  cell.border= {
    top: {style:'thin', color:"€"},
    left: {style:'thin'},
    bottom: {style:'thin'},
    right: {style:'thin'}
  };
  cell.fill = {
    type: 'pattern',
    pattern:'darkVertical',
    bgColor:{argb:'ff1e3194'},
    fgColor:{argb:'ff1e3194'}    
  };
  cell.font = {
    name: 'Arial Black',
    color: { argb: 'FFFFFFFF' },
    family: 2,
    size: 11,
  } 
});

// save under export.xlsx
  workbook.xlsx.writeBuffer('USERS.xlsx').then(function (data) {
    var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    let filename = "Quantum_Users_" + util.convertDate(new Date(), false, ".") + ".xlsx"
    saveAs(blob, filename);
  });




 

}


function  exportUserstoJSON(stations = null){

  //let stations_ = stations ? stations : []
  let {users_filtered} = store_users.getState()
  users_filtered = users_filtered ? users_filtered : []

  let data = []

  users_filtered.forEach((u)=>{

    let user = {
      EntityCode : u.entity_code,
      Entity : (u.station_name + " " + u.country_code),
      Station :  u.region,
      FirstName :  u.first_name,
      LastName :  u.last_name,
      Account :  u.email,
      Role :  util.firstCharToUppercase(u.role),
      EmailContact :  u.email_contact,
      Discovery :  (u.discovery ? "Yes" : "No" ),
      AllowedCarriers :  ( u.carriers ? u.carriers : "None"),
      DeniedCarriers :  (u.except_carriers ? u.except_carriers : "None" ) ,
      FirstLogin :  (!u.first_login ? "Never" : dateToString(new Date(u.first_login))) , 
      LastLogin :  (!u.last_login ? "Never" : dateToString(new Date(u.last_login))) ,
      DateCreated :  dateToString(new Date(u.date_created)), 
      DateUpdated : dateToString(new Date(u.date_updated))
    }

    data.push(user)
   
  });

    var blob = new Blob([JSON.stringify(data)], {type: "application/json"});
    let filename = "Quantum_Users_" + util.convertDate(new Date(), false, ".") + ".json"
    saveAs(blob, filename);

} 

function isConnected(user){
  return user && user.isloggedIn === 'yes' ? true : false
}

async function getConnected(){
  let numberConnected = 0
  
  let users = await Routes.get("/Users", {}, true)

  if(!users) return

  users.forEach(user => {
    if(isConnected(user)) numberConnected ++
  });

  store_users.dispatch({type : 1, countUsers : users.length, connected : numberConnected })

}
