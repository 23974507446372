let env = process.env.REACT_APP_ENV
env = env ? env.toUpperCase() : env
let front_host = "http://localhost:3000"
let back_host = "http://localhost:8080"
let cargoai_shipment = "https://app.dev.cargoai.co/shipment-details"
 
if(env === "PROD"){
	front_host = "https://quantum.digitalfactory.aero"
	back_host = "https://quantum.digitalfactory.aero"
	cargoai_shipment = "https://app.cargoai.co/shipment-details"
}else if(env === "PREPROD"){
	front_host = "https://quantum-preprod.digitalfactory.aero"
	back_host = "https://quantum-preprod.digitalfactory.aero"
	cargoai_shipment = "https://app.testing.cargoai.co/shipment-details"
}else if(env === "DEV"){
	front_host = "https://quantum-dev.digitalfactory.aero"
	back_host = "https://quantum-dev.digitalfactory.aero"
	cargoai_shipment = "https://app.dev.cargoai.co/shipment-details"
}


exports.config = {
	env : env,
	front_host : front_host,
	back_host : back_host,
	cargoai_shipment : cargoai_shipment,
	session_expiration_delay : 20,
	skypallet_end_point : 'https://app.skypallet.io/#/palletize/',
	skypallet_query : 'new-calculation-1/shipment?source=quantum&sourceId=',
	cargospot_email : "cargospot@quito.aero"
}