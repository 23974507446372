export default   {

  reducer_settings: function(state={
    default_config : null,
    force : false,
    custom_list: [], 
    current_station : "***", 
    current_carrier : "***", 
    country_code : "", 
    current_config : {
      sales_region : "",
      country : "",
      sales_conditions : null,
      airline_conditions : null, 
      currencies : [], 
      logo : null, 
      contact_email : null, 
      default_contact : null, 
      quote_main_color : "#003457", 
      quote_font_color : "black", 
      booking_contact_email : null, 
      all_in : false, 
      display_user_name : true,
      uld_pricing : [], 
      quote_validity_period: 14,
    },
    
    date_updated : null,
    update_by : null,
    reset_all_sales_conditions : false,
    loadingSpinner : false,
    print_expand : true,
    gha_expand : false,
    booking_expand : false,
    currency_expand : false,
    uld_expand : false,
    pricing_expand : false,
    portfolio_expand : false,
    refresh : 0,
    addAirline : false,
    updateAirline : false,
    addAirlineModal : false,
    updateAirlineModal : false,
    addGHAModal : false,
    updateGHAModal : false,
    addGHA: 1,
    updateGHAObject : false,
    addGHAconfirm : false,
    updateGHAconfirm : false,
    confirmModal : false,
    confirmModalGHA : false,
    deleteModal : false,
    airline : { 
      id : null, 
      station : "",
      carrier : "",
      country : "", 
      exception_airports : "", 
      quantum_available : 1, 
      contact_emails : ""
    }, 
    airlines  : [],
    gha : { 
      station : "",
      carrier : "",
      country : "", 
      oper_type : "",
      departure_airport : "", 
      gha_name : "", 
      gha_address : "",
      gha_opening_hours : "",
      additional_infos : ""
    }, 
    gha_info  : [],
    array_modal_gha : [],
    update_error : false,
    update_errors : { 
      id : null, 
      station : "", 
      carrier : "", 
      prefix : "", 
      name : "",  
      country : "", 
      exception_airports : "", 
      quantum_available : 1, 
      contact_emails : ""
    }, 
    updateGHAerror : false,
    updateGHAerrors : { 
      station: "",
      carrier: "",
      country : "",
      oper_type : "", 
      departure_airport : "", 
      gha_name : "", 
      gha_address : "",
      gha_opening_hours : "",
      additional_infos : ""
    }, 
    add_error : false,
    add_errors : [],
    carriers_list  : [],
    change : 1,
    errors_message : {}
            
  }, action){

    
    let st = {...state}

    if(action.reset){
      st = {
        default_config : null,
        force : false,
        custom_list: [], 
        current_station : "***", 
        current_carrier : "***", 
        country_code : "", 
        current_config : {
          sales_region : "",
          country : "",
          sales_conditions : null,
          airline_conditions : null, 
          currencies : [], 
          default_currency : "EUR", 
          logo :  null, 
          contact_email : null, 
          quote_main_color : "#003457",
          quote_font_color : "black", 
          booking_contact_email : null, 
          all_in : false, 
          display_user_name : true,
          default_contact : null, 
          uld_pricing : [],
          quote_validity_period : null,
        }, 
        
        date_updated : null,
        update_by : null,
        reset_all_sales_conditions : false , 
        loadingSpinner : false, 
        print_expand : false, 
        gha_expand: false,
        booking_expand : false, 
        currency_expand : false, 
        uld_expand : false, 
        pricing_expand : false,
        portfolio_expand : false, 
        refresh : 0, 
        addAirline : false, 
        updateAirline : false, 
        addAirlineModal : false, 
        updateAirlineModal : false, 
        addGHAModal : false,
        updateGHAModal : false,
        addGHA : 1,
        updateGHAObject : false,
        addGHAconfirm : false,
        updateGHAconfirm : false,
        confirmModal : false, 
        confirmModalGHA : false,
        deleteModal : false, 
        airline : { 
          id : null, 
          station : "", 
          carrier : "", 
          prefix : "", name : "",  
          country : "", 
          exception_airports : "", 
          quantum_available : 1, 
          contact_emails : ""
        }, 
        airlines  : [], 
        gha : { 
          station : "",
          carrier : "",
          country : "", 
          oper_type : "",
          departure_airport : "", 
          gha_name : "", 
          gha_address : "",
          gha_opening_hours : "",
          additional_infos : ""
        }, 
        gha_info  : [],
        array_modal_gha : [],
        update_error : false, 
        update_errors : { 
          id : null, 
          station : "", 
          carrier : "", 
          prefix : "", 
          name : "",  
          country : "", 
          exception_airports : "", 
          quantum_available : 1, 
          contact_emails : ""
        },
        updateGHAerror : false,
        updateGHAerrors : { 
          station: "",
          carrier: "",
          country : "", 
          oper_type : "",
          departure_airport : "", 
          gha_name : "", 
          gha_address : "",
          gha_opening_hours : "",
          additional_infos : ""
        }, 
        add_error : false, 
        add_errors : [], 
        carriers_list  : [], 
        change : 1,
        errors_message : {}

        }
    }
    
    

    if(action.current_config !== undefined){
      
      if(action.current_config !== null){
        st.current_config = {
          sales_region : action.current_config.sales_region !== undefined ? action.current_config.sales_region : st.current_config.sales_region , 
          country : action.current_config.country !== undefined ? action.current_config.country : st.current_config.country , 
          sales_conditions : action.current_config.sales_conditions !== undefined ? action.current_config.sales_conditions : st.current_config.sales_conditions , 
          airline_conditions : action.current_config.airline_conditions !== undefined ? action.current_config.airline_conditions : st.current_config.airline_conditions, 
          currencies : action.current_config.currencies !== undefined ? action.current_config.currencies : st.current_config.currencies, 
          logo : action.current_config.logo !== undefined ? action.current_config.logo : st.current_config.logo, 
          contact_email : action.current_config.contact_email !== undefined ? action.current_config.contact_email : st.current_config.contact_email, 
          default_contact : action.current_config.default_contact !== undefined ? action.current_config.default_contact : st.current_config.default_contact , 
          quote_main_color :  action.current_config.quote_main_color !== undefined ? action.current_config.quote_main_color : st.current_config.quote_main_color,
          quote_font_color : action.current_config.quote_font_color !== undefined ? action.current_config.quote_font_color : st.current_config.quote_font_color,
          default_currency : action.current_config.default_currency !== undefined ? action.current_config.default_currency : st.current_config.default_currency,
          booking_contact_email : action.current_config.booking_contact_email !== undefined ? action.current_config.booking_contact_email : null,
          all_in : action.current_config.all_in !== undefined ? action.current_config.all_in : st.current_config.all_in, 
          display_user_name : action.current_config.display_user_name !== undefined ? action.current_config.display_user_name : st.current_config.display_user_name, 
          uld_pricing : action.current_config.uld_pricing !== undefined ? action.current_config.uld_pricing : st.current_config.uld_pricing,
          quote_validity_period : action.current_config.quote_validity_period !== undefined ? action.current_config.quote_validity_period : st.current_config.quote_validity_period ,
          
        } 

      }else 
        st.current_config = {
          sales_region : "",
          country : "",
          sales_conditions : "", 
          airline_conditions : "", 
          currencies : [], 
          logo : null, 
          contact_email : "", // null, default, user, 
          default_contact : "", 
          quote_main_color :  "#003457",
          quote_font_color : "black", 
          booking_contact_email: "", 
          all_in : false, 
          display_user_name : true,
          uld_pricing : [],
          quote_validity_period : '',
         
        }

    }
  
    let keys = Object.keys(action)

    if(!action.change_config){

      keys.forEach((key)=>{
        if(key === "current_config" || key === "reset") return 
        if(key === "station" || key === 'country' || key === 'exception_airports' || key === 'contact_emails' ) return
        
        st[key] = action[key]
      })

    }


    if(action.change_config)
     
    keys.forEach((key)=>{
        if(key === "current_config" || key === "reset" ) return
        st.current_config[key] = action[key]
      })

    if(action.addLine){
      st.current_config.uld_pricing.push({deck:"lower", position:"", pivot_weight:"", unit_kg:"kg", volume:"", unit : "cbm"})
    }
  
    if(action.removeLine &&   st.current_config.uld_pricing.length > 0 && action.index !== undefined ){
      st.current_config.uld_pricing.splice(action.index, 1)
    }

    if(action.removeLineGHA &&   st.gha_info.length > 0 && action.index !== undefined ){
      st.gha_info.splice(action.index, 1)
    }

    if(action.uld_change && action.index !== undefined){
        if(action.deck !== undefined)
        st.current_config.uld_pricing[action.index].deck = action.deck

        if(action.position !== undefined)
        st.current_config.uld_pricing[action.index].position = action.position

        if(action.pivot_weight !== undefined){
          let pivot_weight  = typeof(action.pivot_weight) === "string" ? 
          isNaN(parseInt(action.pivot_weight)) ? 0 : parseInt(action.pivot_weight) : action.pivot_weight
          st.current_config.uld_pricing[action.index].pivot_weight = pivot_weight
        }
        
        if(action.unit_kg !== undefined)
        st.current_config.uld_pricing[action.index].unit_kg = action.unit_kg

        if(action.volume !== undefined){
          let volume  = typeof(action.volume) === "string" ?
          isNaN(parseFloat(action.volume.replace(",", "."))) ? 0 : parseFloat(action.volume.replace(",", ".")).toFixed(3) : action.volume
          st.current_config.uld_pricing[action.index].volume = volume
        }
       
        if(action.unit !== undefined)
        st.current_config.uld_pricing[action.index].unit = action.unit

    }

    if(action.addLineAirline){

      st.airlines.push({ 
        id : null, 
        station : st.current_station, 
        carrier : "", prefix : "", 
        name : "",  
        country : st.country_code, 
        exception_airports : "", 
        quantum_available : 1, contact_emails : ""
      })
    }

    if(action.removeAirline && st.airlines.length > 0){
      st.airlines.splice(action.index, 1)
    }

    if(action.addLineGHA){

      st.array_modal_gha.push({ 
        station : st.current_station, 
        carrier: (st.current_carrier && st.current_carrier !== "***") ? st.current_carrier : "",        
        country : st.country_code, 
        oper_type : "",
        departure_airport : "", 
        gha_name : "", 
        gha_address : "",
        gha_opening_hours : "", 
        additional_infos : ""
      })
    }

    if(action.removeLineGHA && st.array_modal_gha.length > 0){
      st.array_modal_gha.splice(action.index, 1)
    }

    if(action.removeGHA && st.gha_info.length > 0){
      st.gha_info.splice(action.index, 1)
    }

    if(action.change !== undefined){
      st.change =  st.change + 1 
    }

    if(action.addGHA !== undefined){
      st.addGHA =  st.addGHA + 1 
    }

    if(action.refresh !== undefined){
      st.refresh =  st.refresh ?  st.refresh + 1 : 1
    }

    if(action.airline !== undefined){
      st.airline =  action.airline
    }

    if(action.airlines !== undefined){
      st.airlines = action.airlines
    }

    if(action.gha !== undefined){
      st.gha =  action.gha
    }

    if(action.gha_info !== undefined){
      st.gha_info = action.gha_info
    }

    if(action.array_modal_gha !== undefined){
      st.array_modal_gha = action.array_modal_gha
    }

    if(action.change && action.index !== undefined){

      let index = action.index

      if(action.station !== undefined)
        st.airlines[index].station = action.station

      if(action.carrier !== undefined){
        st.airlines[index].carrier = action.carrier
        st.change =  st.change + 1 
      }
        
      if(action.country !== undefined)
        st.airlines[index].country = action.country

      if(action.prefix !== undefined)
        st.airlines[index].prefix = action.prefix

      if(action.name !== undefined)
        st.airlines[index].name = action.name

      if(action.exception_airports !== undefined){
        let airports = action.exception_airports
        for(let i = 0 ; i< 10; i++){
          airports = airports ? airports.replace("  ", " ").replace(" ", ",").replace(';',",").trim() : ""
        }
        st.airlines[index].exception_airports = airports
      }
        
 
      if(action.quantum_available !== undefined)
        st.airlines[index].quantum_available = action.quantum_available ? 1 : 0

      if(action.contact_emails !== undefined){
        let contacts = action.contact_emails
        for(let i = 0 ; i< 10; i++){
          contacts = contacts ? contacts.toLowerCase().replace("  ", " ").replace(" ", ";").replace(',', ';').trim() : ""
        }
        st.airlines[index].contact_emails =contacts

      }
        
    }

    if(action.change && !action.index){

      if(action.station !== undefined)
        st.airline.station = action.station

      if(action.carrier !== undefined){
        st.airline.carrier = action.carrier
        st.change =  st.change + 1 
      }
        
      if(action.country !== undefined)
        st.airline.country = action.country

      if(action.prefix !== undefined)
        st.airline.prefix = action.prefix

      if(action.name !== undefined)
        st.airline.name = action.name

      if(action.exception_airports !== undefined){
        let airports = action.exception_airports
        for(let i = 0 ; i< 10; i++){
          airports = airports ? airports.replace("  ", " ").replace(" ", ",").replace(';',",").trim() : ""
        }
        st.airline.exception_airports = airports
      }
        
 
      if(action.quantum_available !== undefined)
        st.airline.quantum_available = action.quantum_available ? 1 : 0

      if(action.contact_emails !== undefined){
        let contacts = action.contact_emails
        for(let i = 0 ; i< 10; i++){
          contacts = contacts ? contacts.toLowerCase().replace("  ", " ").replace(" ", ";").replace(',', ';').trim() : ""
        }
        st.airline.contact_emails =contacts

      }
        
    }

    if(action.addGHA && action.index !== undefined){
      if(action.departure_airport !== undefined)
        st.array_modal_gha[action.index].departure_airport = action.departure_airport

      if(action.via_airport!== undefined)
        st.array_modal_gha[action.index].via_airport = action.via_airport

      if(action.gha_name !== undefined)
        st.array_modal_gha[action.index].gha_name = action.gha_name

      if(action.gha_address !== undefined)
        st.array_modal_gha[action.index].gha_address = action.gha_address

      if(action.gha_opening_hours !== undefined)
        st.array_modal_gha[action.index].gha_opening_hours = action.gha_opening_hours

      if(action.additional_infos !== undefined)
        st.array_modal_gha[action.index].additional_infos = action.additional_infos

      if(action.oper_type !== undefined)
        st.array_modal_gha[action.index].oper_type = action.oper_type

    }

    if (action.updateGHAObject){
      if(action.departure_airport !== undefined)
       st.gha.departure_airport = action.departure_airport

      if(action.via_airport !== undefined)
        st.gha.via_airport = action.via_airport
 

      if(action.gha_name !== undefined)
        st.gha.gha_name = action.gha_name

      if(action.gha_address !== undefined)
        st.gha.gha_address = action.gha_address

      if(action.gha_opening_hours !== undefined)
        st.gha.gha_opening_hours = action.gha_opening_hours

      if(action.additional_infos !== undefined)
        st.gha.additional_infos = action.additional_infos

      
      if(action.oper_type !== undefined)
        st.gha.oper_type = action.oper_type
    }


    if(action.addGHAconfirm !== undefined){
      st.addGHAconfirm = action.addGHAconfirm
    }


    if(action.updateGHAconfirm !== undefined){
      st.updateGHAconfirm = action.updateGHAconfirm
    }


   
    if(action.update_error !== undefined){

      if(action.id !== undefined)
        st.update_errors.id = action.id
      
      if(action.station !== undefined)
        st.update_errors.station = action.station

      if(action.carrier !== undefined)
        st.update_errors.carrier = action.carrier

      if(action.country !== undefined)
        st.update_errors.country = action.country

      if(action.prefix !== undefined)
        st.update_errors.prefix = action.prefix

      if(action.name !== undefined)
        st.update_errors.name = action.name

        
      if(action.exception_airports !== undefined)
        st.update_errors.exception_airports = action.exception_airports

      if(action.exception_airports !== undefined)
        st.update_errors.exception_airports = action.exception_airports

      if(action.contact_emails !== undefined)
        st.update_errors.contact_emails = action.contact_emails
    }

    if(action.updateGHAerror !== undefined){

      if(action.departure_airport !== undefined)
        st.updateGHAerrors.departure_airport = action.departure_airport

      if(action.via_airport !== undefined)
        st.updateGHAerrors.via_airport = action.via_airport

      if(action.gha_name !== undefined)
        st.updateGHAerrors.gha_name = action.gha_name

        
      if(action.gha_address !== undefined)
        st.updateGHAerrors.gha_address = action.gha_address

      if(action.gha_opening_hours !== undefined)
        st.updateGHAerrors.gha_opening_hours = action.gha_opening_hours

    
    }

    if(action.add_errors !== undefined ){

      st.add_errors = action.add_errors
     
    }

    return st 

  }


}