import axios from 'axios';
import conf from './config.js'
import cookies from './Cookies.js';
 
var config = conf.config ;
var endpoint =  config.back_host;
 
export default {

call : async function(route, params, withCredentials = true){
    let url = endpoint + route
    return await axios.get(url,{withCredentials : withCredentials, params : params})
    .then(response=>{
        return (response.data)
    }).catch( (e)=>{
        console.log('error',e.toString())
        return null 
    }); 
},

get : async function(route, params, withCredentials = true){
  let url = endpoint + route
  return await axios.get(url,{withCredentials : withCredentials, params : params})
  .then(response=>{
      return (response.data)
  }).catch( (e)=>{
      console.log('error',e.toString())
      return null 
  }); 
},

post : async function (route, params,withCredentials = true){
  let url = endpoint + route
  return await axios({
    method: 'post',
    url: url ,
    timeout : 60*1000,
    withCredentials : withCredentials,
    data: encodeURIComponent(JSON.stringify(params)),
}).then((response)=>{
    return response.data

  }).catch((error)=>{
    console.log('error',error.toString())
    return null 
  })

},

delete : async function (route, params){
  let url = endpoint + route
  return await axios.delete(url, {data : params}).then((response)=>{
    return response.data
  }).catch((error)=>{
    console.log('error',error.toString())
    return null 
  })

},
 
saveQuote : async function (params)  {
         
        let url = endpoint +"/Quotes"
        
        let params2 = {...params}

        return await  axios({
          method: 'post',
          url: url,
          withCredentials : true,
          data: encodeURIComponent(JSON.stringify(params2)),
          timeout : 60000

      }).then(function (response) {
      
      
        if(response.data.id)
            return response.data.id
        
        return false
           
      }).catch(function (error ) {
        console.log("error back", error )
        if(error.response && error.response.data)
        return error.response.data
        else  return {error:true, message : "Unspecified error."}     
      });
      
},

getQuotes : async function (params, callback) {
        console.log("[CALL] getQuotes", params)
      

        return await axios.get(
          endpoint + "/Quotes" ,
          { timeout : 60000,
            withCredentials : true,
            params : {
              station : params.station === "All" ? undefined : params.station,
              region : params.station === "All" ? cookies.region : undefined,
              limit : params.limit && !isNaN(params.limit) ? params.limit : 100,
              origin : params.origin,
              dest : params.dest,
              creation_from_date : params.from_date,
              creation_to_date : params.to_date,
              first_flight_fromDate : params.first_flight_fromDate,
              first_flight_toDate : params.first_flight_toDate,
              hq : params.hq,
              skip : params.skip,
              user : params.user,
              status : params.status === "" || params.status === "All" ? undefined : params.status,
              adhoc_ref : params.adhoc_ref === "" ? undefined : params.adhoc_ref,
              quote_id : !params.quote_id && params.quote_id === "" ? undefined : params.quote_id,
              carrier : params.carrier !== "" && params.carrier !== null ? params.carrier : undefined,
              awb_serial : params.awb_serial !== "" && params.awb_serial !== null ? params.awb_serial : undefined,
              awb_prefix : params.awb_prefix !== "" && params.awb_prefix !== null ? params.awb_prefix : undefined,
              commodity_code : params.commodity_code === "" || params.commodity_code === 'All' ? undefined : params.commodity_code,
              dimension_type : !params.dimension_type || params.dimension_type === 'All' ? undefined : params.dimension_type,
              deck : !params.deck || params.deck === 'All' ? undefined : (params.deck === 'main' ?  1 : 0),
              agent : params.agent ? params.agent : undefined,
              min_weight : params.min_weight ? params.min_weight : undefined,
              max_weight : params.max_weight ? params.max_weight : undefined,
              exclude_id : params.exclude_id,
              exclude_status : params.exclude_status ? params.exclude_status : undefined,
              id : params.id,
              external_id : params.external_id,
              user_follow_up : params.user_follow_up,
              follow_up : params.follow_up,
              plugin : params.plugin
            }
          }
        ).then((response)=>{
      
          return response.data;
      
        }).catch((error)=>{
          
          return window.location.replace(config.front_host)
          
      
        })
      
},

getKPI : async function(params){
        let url = endpoint +"/KPI";
          return await axios.get(url, {withCredentials : true, params : params})
        .then((response)=>{
          return { status : true, data : response.data}
        }).catch((error)=>{
          console.log(error)
          if(error.response)
          return {status : false, data : null, msg : error.response.data.message}
          else return  window.location.replace(config.front_host)

        })
},

sendEquote : async function (params){
    let url = endpoint + "/sendEquote" 
    return await axios({
      method: 'post',
      url: url ,
      withCredentials : true,
      timeout:30*1000,
      data: encodeURIComponent(JSON.stringify(params)),
  }).then((response)=>{
      return response.data
    }).catch((error)=>{
      return error && error.response && error.response.data ?
      error.response.data :  {error : true, statusCode : 500, message : "Internal Server Error" }
    })
  
},

sendQuote : async function (params){
  let url = endpoint + "/sendQuote" 
  return await axios({
    method: 'post',
    url: url ,
    withCredentials : true,
    timeout:30*1000,
    data: encodeURIComponent(JSON.stringify(params)),
}).then((response)=>{
    return response.data
  }).catch((error)=>{
    console.log(error)
    return error && error.response && error.response.data ?
     error.response.data :  {error : true, statusCode : 500, message : "Internal Server Error" }
  })

}


}