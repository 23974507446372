import React from "react";
import watch from 'redux-watch'
import { connect } from 'react-redux';
import { createStore } from 'redux';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FormControlLabel, Checkbox  } from "@material-ui/core";
import cookies from "../Cookies.js";
import conf from '../config.js'
import Util from "../util.js";
import Routes from '../Routes.js'
import Profile_State from '../states/Profile_State.js'


var store_profile = createStore(Profile_State.reducer_profil)




var config = conf.config ;


class Main extends React.Component {
constructor(props) {
    super(props);  
    this.state = {
      loaded : false, 
      loadTry : 0
    };
  }

async componentDidMount() {

  document.title = "Quantum - Profil"; 

  let user = await Routes.get("/Users", {email : cookies.account}, true)
  
  store_profile.dispatch({type : 1, user : user ? user[0] : null  }) 

}

 
render() {

  if(!this.props.loggedIn) {
    return window.location.replace(config.front_host)
  } 

  return (
   
    <div  key={this.state.key}>
          <ExpansionPanel 
          defaultExpanded = {true}
          square = {true}
          onChange = {(e) => {}}
      >
            
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
       
      </ExpansionPanelSummary>
          
      <ExpansionPanelDetails>
        <div class = "col-3"></div>
        <div class = "col-6">
          <ProfilForm/>         
        </div> 
        <div class = "col-2"></div>
        <br/>
        <br/>
        <br/>
        <br/>
      </ExpansionPanelDetails>

      </ExpansionPanel>
    </div>
   

      
 
  )
}
}
 

class ProfilForm extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      key : Date.now(),
      current_input : null, 
      newLine : null
    }

  }

  componentDidMount(){
      store_profile.subscribe(()=>{
      this.setState({key:Date.now()})})
      let w1 = watch(store_profile.getState, 'user')
      store_profile.subscribe(w1((newVal, oldVal, objectPath) => {
        this.setState({key : Date.now()})
      }))
  }

  componentDidUpdate(){ 

  }

  render(){
    
    let user = store_profile.getState().user
    user = user ? user : {}
    
    let role = ""
    if(user.role === Util.USER_ROLES.ADMIN) 
      role = "Administrator"
    else if(user.role === Util.USER_ROLES.STATION_ADMIN ) 
      role = "Station Admin"
    else if(user.role === Util.USER_ROLES.SALES_STD) 
      role = "Sales STD"
    else if(user.role === Util.USER_ROLES.SALES_LITE) 
      role = "Sales Lite"
    else if(user.role === Util.USER_ROLES.OPS_LITE) 
      role = "Ops Lite"
    else if(user.role === Util.USER_ROLES.OPS_STD) 
      role = "Ops STD"
  

    return(
      <div key={this.state.key}>
        

          <div class = 'row'  style = {this.modal_style}>

            <div class="col-6">
              <h2><strong>My Profile</strong></h2>
            </div>

          </div>
          <div class = 'row'  style = {this.modal_style}>


          <div class="col-6">
            <label ><strong>First Name</strong></label>
            <input type="text" class= {"form-control"} defaultValue={user.first_name ? user.first_name : ""} disabled ={true} />
          </div>

          <div class="col-6">
            <label ><strong>Last Name</strong></label>
            <input type="text" class= {"form-control"} defaultValue={user.last_name ? user.last_name : ""} disabled = {true}/>
          </div>


          </div>

          <div class = 'row'  style = {this.modal_style}>

            <div class="col-6">
              <label ><strong>Entity</strong></label>
              <input type="text" class= {"form-control"}  
              defaultValue={user.station_name ? user.station_name + (user.country_code ? " - " + user.country_code : "") + ((user.entity_code ? " (" + user.entity_code  + ")": "")): ""}
               disabled = {true}/>
            </div>

            <div class="col-6">
              <label ><strong>Stations</strong></label>
              <input type="text" class= {"form-control"}  defaultValue={user.region ? user.region : ""} disabled = {true}/>
            </div>

          </div>

          <div class = 'row'  style = {this.modal_style}>

          <div class="col-6">
            <label ><strong>Account</strong></label>
            <input type="text" class= {"form-control"} defaultValue={user.email ? user.email : ""} disabled = {true}/>
          </div>

          <div class="col-6">
            <label ><strong>Email Contact</strong></label>
            <input type="text"class= {"form-control"} defaultValue={user.email_contact ? user.email_contact : ""} disabled = {true}/>
          </div>

          </div>

          <div class = 'row'  style = {this.modal_style}>
          <div class="col-6">
            <label ><strong>Allowed Carriers</strong></label>
            <input type="text" class= {"form-control"}  defaultValue={user.carriers ? user.carriers : "None"} disabled = {true}/>
          </div>

          <div class="col-6">
            <label ><strong>Denied Carriers</strong></label>
            <input type="text" class= {"form-control"}  defaultValue={user.except_carriers ? user.except_carriers : "None"} disabled = {true}/>
          </div>
          </div>

          <div class = 'row'  style = {this.modal_style}>

          <div class="col-10">
            <label ><strong>Role</strong> </label>
            <input type="text"class= {"form-control"} defaultValue={role} disabled = {true}/>
          </div>


          <div class="col-2" style={{textAlign : "center" }}>
            <FormControlLabel
              value="bottom"
              control = {        
                <Checkbox
                  defaultChecked = {user.discovery !== undefined ? user.discovery : false}
                  disabled = {true}
                  color="primary"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                />
              }
              label={"Discovery"}
              labelPlacement="top"
            
            />
          </div>

          </div>

          <div class = 'row'    style = {this.modal_style}>

          <div class="col-3">
          <label ><strong>Date Creation</strong></label>
          <input 
            type="text" class= "form-control"
            disabled 
            defaultValue={user.date_created ? Util.dateToString(new Date(user.date_created)) : ""} 
          />
          </div>


          <div class="col-3">
          <label ><strong>Last Update</strong></label>
          <input 
            type="text" 
            class= "form-control" 
            disabled
            defaultValue={user.date_updated ? Util.dateToString(new Date(user.date_updated)) : ""} 
          />
          </div>

          <div class="col-3">
          <label ><strong>First Login</strong></label>
          <input 
            type="text" 
            class= "form-control"
            disabled
            defaultValue={user.first_login ? Util.dateToString(new Date(user.first_login)) : "Never"} 
          />
          </div>

          <div class="col-3">
          <label ><strong>Last Login</strong></label>
          <input 
            type="text" 
            class= "form-control"
            disabled
            defaultValue={user.last_login ? Util.dateToString(new Date(user.last_login)) : "Never"} 
          />
          </div>

          </div>

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
      </div>
    )
  }

}

export default connect(
    (state) => ({
      authorized_stations: state.authorized_stations, 
      loggedIn : state.loggedIn
    })
)(Main) 

